import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

export default function PublishRequiredDialog({ open, handleClose, handleSave }) {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle display='flex' alignItems='center'>
                <WarningAmberIcon sx={{ mr: 1 }} />
                <Typography variant="title1">
                    Republicação necessária
                </Typography>
            </DialogTitle>

            <DialogContent dividers>
                <Typography align="justify" variant="body1" sx={{ mb: 2 }}>
                    As modificações realizadas nesta seção só terão efeito após a publicação de uma atualização para o seu aplicativo. Por este motivo, será necessário que você solicite uma nova publicação.
                </Typography>
                <Typography align="justify" variant="body1">
                    Você tem certeza que deseja salvar as alterações realizadas?
                </Typography>
            </DialogContent>

            <DialogActions sx={{ py: 2, px: 3 }}>
                <Button
                    onClick={handleClose}
                >
                    Descartar alterações
                </Button>

                <Button
                    onClick={handleSave}
                    type="submit"
                    variant="contained"
                >
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    )
}