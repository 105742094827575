import React from 'react';
import { Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useAppInfo from '../../../hooks/appInfo.hook';

const useStyles = makeStyles(() => ({
    card: {
        alignItems: 'center',
        backgroundColor: '#2196f3',
        border: 0,
        borderRadius: 4,
        color: '#fff',
        display: 'flex',
        marginBottom: 20,
        minHeight: 35,
        padding: '7px 10px',
        width: 'fit-content'
    }
}))

export default function TrialAlert() {
    const classes = useStyles();
    const { getTrialDaysLeft } = useAppInfo()

    const trialDaysLeft = getTrialDaysLeft();

    return (
        <div className={classes.card}>
            <InfoOutlinedIcon sx={{ marginRight: 1}} />
            <Typography align="left" variant="body2">
                Seu teste grátis termina em {trialDaysLeft} {trialDaysLeft === 1 ? 'dia' : 'dias'}
            </Typography>
        </div>
    )
}
