import React, { useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import PushForm from './PushForm';
import _ from 'lodash';
import DashboardLayout from '../../../layouts/Dashboard';
import { Grid } from '@mui/material';
import PushPreviewer from './previewer/PushPreviewer';
import { mobilizeiPushNotificationsService } from '../../../services/mobilizei/pushNotifications.service';
import { useUser } from "../../../contexts/user.context";
import { toast } from 'react-toastify';

export default function Push() {
    const { user } = useUser();

    const [payload, setPayload] = useState({ title: '', text: '', action: 'noAction' });
    const [isSending, setIsSending] = useState(false);

    const handlePayloadChange = (property, value) => {
        let newPayload = JSON.parse(JSON.stringify(payload));
        _.set(newPayload, property, value);

        if (property === 'action') {
            _.set(newPayload, 'link', '');
        }

        setPayload(newPayload);
    }

    function handleSend() {
        setIsSending(true);

        mobilizeiPushNotificationsService.sendPushNotification(
            user?.store?.platformId,
            user?.store?.id,
            payload
        )
            .then(() => {
                toast.success('Notificação enviada com sucesso!')
            })
            .catch(() => {
                toast.error('Não foi possível enviar a notificação. Por favor, tente novamente.')
            })
            .finally(() => {
                setIsSending(false);
            })
    }

    return (
        <DashboardLayout
            Icon={SendIcon}
            title="Enviar notificação push"
            subtitle="Escreva e envie uma mensagem para todos os usuários do seu aplicativo"
        >
            <Grid container>
                <Grid item md={5} xs={12}>
                    <PushForm
                        isSending={isSending}
                        onPayloadChange={handlePayloadChange}
                        onSend={handleSend}
                        payload={payload}
                        showSendButton
                    />
                </Grid>

                <Grid item md={7} xs={12}>
                    <PushPreviewer payload={payload} />
                </Grid>
            </Grid>
        </DashboardLayout>
    )
}
