import React from 'react';
import { makeStyles } from '@mui/styles';
import { PLATFORMS, planPeriodicity } from '../../../utils/constants';
import { useUser } from '../../../contexts/user.context';

const useStyles = makeStyles(() => ({
    periodSelector: {
        display: "flex",
        flexDirection: "row",
        height: "60px",
        alignItems: "center",
        alignSelf: 'center',
        justifySelf: 'center',

        gap: "10px",
        backgroundColor: "#fff",
        borderRadius: 50,
        padding: "2px 6px",
        boxShadow: "0px 0px 24px 0px #00000014",
    },
    periodButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100px",
        height: "50px",
        position: 'relative',

        cursor: "pointer",
        userSelect: "none",

        background: "#fff",
        borderRadius: 50,
        padding: "10 20 2 20",
    },
    periodButtonText: {
        color: "#000",
        fontSize: 16,
        lineHeight: 24,
        textAlign: "center",
        fontFamily: 'Cabin, sans-serif',
    },
    periodButtonSel: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100px",
        height: "50px",
        position: 'relative',

        cursor: "pointer",
        userSelect: "none",

        background: "#000",
        borderRadius: 50,
        padding: "10 20 2 20",
    },
    periodButtonTextSel: {
        color: "#fff",
        fontSize: 16,
        lineHeight: 24,
        textAlign: "center",
        fontFamily: 'Cabin, sans-serif',
    },
    discountTag: {
        background: '#3cd568',
        border: '1px solid #fff',
        borderRadius: 50,
        color: '#fff',
        fontSize: 12,
        fontWeight: 700,
        padding: '2px 7px',
        position: 'absolute',
        right: -20,
        top: -14
    }
}));

export default function PeriodSelector({ periodicity, onSelect }) {
    const { user } = useUser();
    const classes = useStyles();

    const isYearly = periodicity == planPeriodicity.YEARLY;
    const isMonthly = periodicity == planPeriodicity.MONTHLY;

    return (
        <div className={classes.periodSelector}>
            <div
                className={isMonthly ? classes.periodButtonSel : classes.periodButton}
                onClick={() => onSelect(planPeriodicity.MONTHLY)}
            >
                <h1 className={isMonthly ? classes.periodButtonTextSel : classes.periodButtonText}>
                    Mensal
                </h1>
            </div>

            <div
                className={isYearly ? classes.periodButtonSel : classes.periodButton}
                onClick={() => onSelect(planPeriodicity.YEARLY)}
            >
                <h1 className={isYearly ? classes.periodButtonTextSel : classes.periodButtonText}>
                    Anual
                </h1>
                <span className={classes.discountTag}>-15%</span>
            </div>
        </div>
    )
}