import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Container } from '@mui/material';
import Header from './Header';
import DrawerMenu from './DrawerMenu';

const useStyles = makeStyles(() => ({
    stripe: {
        top: 0,
        left: 0,
        right: 0,
        height: 200,
        position: 'fixed',
        background: '#404040',
        pointerEvents: 'none',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
    },
    contentWrapper: {
        display: 'flex',
        flexGrow: 1,
        boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
        borderRadius: '8px 8px 0 0',
        flexDirection: 'column',
        backgroundColor: '#fff'
    }
}));

export default function DashboardLayout({ Icon, title, subtitle, children }) {
    const classes = useStyles();

    return (
        <Box sx={{ display: 'flex', position: 'relative' }}>
            <DrawerMenu />

            <Box className={classes.stripe} sx={{ mt: { lg: 0, xs: '64px' } }} />

            <Box component="main"
                sx={{
                    display: 'flex',
                    flexGrow: 1,
                    height: { lg: '100vh', xs: 'calc(100vh - 64px)' },
                    mt: { lg: 0, xs: '64px' },
                    overflow: 'auto',
                    zIndex: 10
                }}
            >
                <Container maxWidth="lg" className={classes.container}>
                    <Header Icon={Icon} title={title} subtitle={subtitle} />

                    <Box className={classes.contentWrapper}>
                        <Container maxWidth="lg" className={classes.container}>
                            {children}
                        </Container>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}