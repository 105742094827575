import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Link, Stack, Typography } from '@mui/material';
import ColorPicker from '../../../components/ColorPicker';
import { useOnboarding } from '../../../contexts/onboarding.context';
import { mobilizeiStoresService } from '../../../services/mobilizei/stores.service';
import _ from 'lodash';
import { useUser } from '../../../contexts/user.context';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
    wrapper: {
        background: '#fff',
        borderRadius: 5,
        boxShadow: '0 -1px 8px 2px rgba(0,0,0,.08), 0 1px 8px 2px rgba(0,0,0,.08), 2px 0 8px 2px rgba(0,0,0,.08), -2px 0 8px 2px rgba(0,0,0,.08)',
        width: 320
    },
    title: {
        padding: '20px 20px 0 20px'
    },
    body: {
        borderTop: '1px solid #cecece',
        padding: '15px 20px 20px 20px'
    }
}));

export default function TabBarColors() {
    const classes = useStyles();

    const { user } = useUser()

    const { theme, setTheme } = useOnboarding();
    const tabBarData = theme?.settings.find(s => s.key === 'tabBar');

    const [canUpdateTheme, setCanUpdateTheme] = useState(false);

    const handleChange = (property, color) => {
        let themeCopy = JSON.parse(JSON.stringify(theme));
        const index = _.findIndex(themeCopy.settings, { 'key': 'tabBar' });
        _.set(themeCopy, `settings[${index}].options[${property}]`, color);

        setTheme(themeCopy);
        setCanUpdateTheme(true);
    }

    useEffect(() => {
        // Function will launch 3000 ms after the last change
        // on the theme object. We use the canUpdateTheme to
        // prevent an unecessary API request on the first render.
        let timer = setTimeout(() => {
            if (canUpdateTheme && user?.store?.platformId && user?.store?.id) {
                mobilizeiStoresService.setTheme(
                    user?.store?.platformId,
                    user?.store?.id,
                    'webview',
                    theme
                ).catch((e) => {
                    console.log('Error updating theme', e)
                })
            }
        }, 3000)

        // If useEffect() relaunches, we clear the function so the 
        // previous function won't launch. We do that to avoid to
        // send an unecessary request to the API.
        return () => clearTimeout(timer)
    }, [theme])

    if (!tabBarData)
        return <div />

    return (
        <Stack spacing={1} alignItems="center" className={clsx(classes.wrapper, 'onboardingPreviewerTabBarColors')}>
            <Typography variant="body1" align='center' className={classes.title} fontWeight={'bold'}>
                Menu inferior
            </Typography>

            <Stack alignItems="left">
                <ColorPicker
                    label='Cor de fundo'
                    initialColor={tabBarData?.options?.backgroundColor}
                    onChange={(color) => handleChange('backgroundColor', color)}
                />

                <ColorPicker
                    label='Cor do ícone selecionado'
                    initialColor={tabBarData?.options?.activeIconColor}
                    onChange={(color) => handleChange('activeIconColor', color)}
                />

                <ColorPicker
                    label='Cor do ícone não selecionado'
                    initialColor={tabBarData?.options?.iconColor}
                    onChange={(color) => handleChange('iconColor', color)}
                />
            </Stack>

            <Typography variant="body2" align='left' className={classes.body}>
                Acesse o
                <Link href="/" variant="body2">
                    {' '}painel de controle{' '}
                </Link>
                do seu aplicativo para ver todas as
                opções de personalização disponíveis.
            </Typography>
        </Stack>
    );
}