import { Box, Button, Checkbox, Dialog, DialogContent, Typography } from '@mui/material';
import React, { useState } from 'react';

export default function CompanyAccountDialog(props) {
    const [isCompanyAccountChecked, setIsCompanyAccountChecked] = useState(false);

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
        >
            <DialogContent>
                <Typography align="justify" variant="h5">
                    Atenção
                </Typography>
            </DialogContent>
            <DialogContent>
                <Typography align="justify" variant="subtitle1" sx={{ marginBottom: 2 }}>
                    Somente com uma conta de desenvolvedor Apple do tipo <strong>"Empresarial" (Company / Organization)</strong> será possível publicar a versão iOS do seu aplicativo! Caso você possua outro tipo de conta, <strong>a publicação do seu aplicativo iOS não poderá ser realizada.</strong>
                </Typography>
                <Typography align="justify" variant="subtitle1" sx={{ marginBottom: 2 }}>
                    Caso você não tenha certeza qual é o tipo da sua conta de desenvolvedor Apple, <a href="https://mobilizei.com.br/tutoriais/conta-de-desenvolvedor-apple-empresarial-ou-individual" target="_blank" style={{ color: '#3cd568', textDecoration: 'none' }}>clique aqui</a> e veja como descobrir.
                </Typography>

                <Typography align="justify" variant="subtitle1" sx={{ marginBottom: 2 }}>
                    Se a sua conta não for "Empresarial" (Company / Organization), <a href="https://mobilizei.com.br/tutoriais/como-alterar-conta-apple-de-individual-para-empresarial" target="_blank" style={{ color: '#3cd568', textDecoration: 'none' }}>veja aqui</a> como solicitar para a Apple uma troca do tipo da conta.
                </Typography>

                <Box display='flex' alignItems='center' marginBottom={1} marginTop={4} sx={{ cursor: 'pointer' }} onClick={() => setIsCompanyAccountChecked(!isCompanyAccountChecked)}>
                    <Checkbox checked={isCompanyAccountChecked} />
                    <Typography align="justify" variant="subtitle1">
                        Possuo uma conta de desenvolvedor Apple do tipo "Empresarial"
                    </Typography>
                </Box>

                <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column'>
                    <Button disabled={!isCompanyAccountChecked} variant="contained" onClick={() => props.goToNextStep()} >
                        próximo
                    </Button>
                </Box>

            </DialogContent>

        </Dialog>
    )
}