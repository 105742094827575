import React, { useRef, useState } from 'react';
import { Box, Card, CircularProgress, Typography } from "@mui/material";
import { mobilizeiStoresService } from '../services/mobilizei/stores.service';
import { useUser } from "../contexts/user.context";
import { showApiError } from '../utils/errors';
import { toast } from 'react-toastify';
import BlockIcon from '@mui/icons-material/Block';
import CloudUploadIcon from '@mui/icons-material/CloudUploadOutlined';
import { formatFileName } from '../utils/format';

export default function ImageUploader(props) {
    const { user } = useUser();

    const fileInputRef = useRef(null);

    const displayWidth = 20;
    const displayHeight = (displayWidth * props.height) / props.width;

    const [isLoading, setIsLoading] = useState(false);

    const getActionIcon = color => props.disabled ?
        <BlockIcon color={color} fontSize='large' /> :
        <CloudUploadIcon color={color} fontSize='large' />

    function onUploadSuccess(downloadURL) {
        setIsLoading(false);

        props.onUploadSuccess(downloadURL, props.urlParameterName);
    }

    function handleUploadChange(e) {
        const file = e.target.files[0];
        if (!file) {
            return;
        }

        let img = new Image();
        const _URL = window.URL || window.webkitURL;

        img.src = _URL.createObjectURL(file);
        img.onload = function () {
            const imgwidth = this.width;
            const imgheight = this.height;

            if (imgwidth === props.width && imgheight === props.height) {
                setIsLoading(true);

                let imageData = new FormData();
                imageData.append("image", file, formatFileName(file.name));

                mobilizeiStoresService.uploadFile(
                    user?.store?.platformId,
                    user?.store?.id,
                    imageData
                )
                    .then((response) => {
                        onUploadSuccess(response?.fileUrl, img.height, img.width)
                    })
                    .catch(e => {
                        showApiError(e.error);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    })

            } else {
                toast.error(`A imagem deve ter ${props.width} x ${props.height} px`)
            }
        }
    }

    return (
        <Box display='flex' justifyContent='start' marginBottom={4}>
            <input
                accept="image/*"
                onChange={handleUploadChange}
                ref={fileInputRef}
                style={{ display: 'none' }}
                type="file"
            />

            <Card
                elevation={2}
                onClick={() => props.disabled ? null : fileInputRef.current.click()}
                sx={{
                    alignItems: 'center',
                    cursor: 'pointer',
                    display: 'flex',
                    height: `${displayHeight}vw`,
                    justifyContent: 'center',
                    maxWidth: `${displayWidth}vw`,
                    minWidth: `${displayWidth}vw`,
                    position: 'relative',
                    "&:hover": {
                        boxShadow: 6
                    }

                }}
            >
                {isLoading ? (
                    <CircularProgress />
                ) : (
                    props.uploadedImageUrl ? (
                        <>
                            <Box
                                alignItems='center'
                                display='flex'
                                justifyContent='center'
                                sx={{
                                    backgroundColor: 'black',
                                    opacity: 0,
                                    position: 'absolute',
                                    height: '100%',
                                    width: '100%',
                                    "&:hover": {
                                        opacity: 0.75
                                    }
                                }}
                            >
                                {getActionIcon('white')}
                            </Box>
                            <img width='100%' height='auto' src={props.uploadedImageUrl} />
                        </>
                    ) : (
                        getActionIcon('action')
                    )
                )}
            </Card>

            <Box marginLeft={2}>
                <Typography variant='h6'>{props.title}</Typography>
                {props.description}
            </Box>
        </Box >
    )
}