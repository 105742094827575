import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import _ from 'lodash';
import { mapIntegerInCentsToCurrency } from '../../utils/mappers';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    qrCodeWrapper: {
        lineHeight: 0,
        "& img": {
            border: '1px solid #cccccc',
            maxWidth: '100%'
        },
        "& button": {
            borderRadius: 0,
            textTransform: 'none',
            width: '100%'
        }
    },
    receiverInfoLine1: {
        marginBottom: 5,
        marginTop: 10
    }
}));

export default function PixData({ title, text, disclaimer, invoice }) {
    const classes = useStyles();
    const [pixButtonText, setPixButtonText] = useState('Pix Copia e Cola');

    const navigate = useNavigate();

    const copyPixCode = () => {
        navigator.clipboard.writeText(_.get(invoice, 'pix.qrCodeText', ''))
        setPixButtonText('Copiado!');

        setTimeout(() => {
            setPixButtonText('Pix Copia e Cola');
        }, 2000);
    }

    return (
        <Container maxWidth="sm">
            {!_.isEmpty(title) &&
                <Box mb={6}>
                    <Typography variant="h4" align="center">
                        {title}
                    </Typography>
                </Box>
            }

            <Grid container direction="row" spacing={2}>
                {!_.isEmpty(text) &&
                    <Grid item xs={12}>
                        <Box marginBottom={8}>
                            <Typography variant="body1" align="center">
                                {text}
                            </Typography>
                        </Box>
                    </Grid>
                }

                <Grid container>
                    <Grid item xs={6} sm={4} className={classes.qrCodeWrapper}>
                        <Grid item xs={12}>
                            <img src={_.get(invoice, 'pix.qrCode', '')} alt="Pix QR Code" />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                disableElevation
                                onClick={() => copyPixCode()}
                                sx={{ fontSize: 14 }}
                            >
                                {pixButtonText}
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid item xs={6} sm={8}>
                        <Box paddingX={2}>
                            <Typography variant="body2" gutterBottom>
                                Para efetuar o pagamento, escaneie o QR Code ao lado no aplicativo do seu banco ou então clique no botão para copiar o código "Pix Copia e Cola".
                            </Typography>

                            <Typography variant="body2" className={classes.receiverInfoLine1}>
                                O pagamento será realizado para:
                            </Typography>

                            <Typography variant="body2">
                                <b>Nome:</b> Bit Desenvolvimento de Software Eireli <br />
                                <b>Instituição:</b> Iugu Ip S.A. <br />
                                <b>CNPJ:</b> 29.827.810/0001-06
                            </Typography>

                            <Box mt={3}>
                                <Typography variant="body1" align="left">
                                    <strong>Valor: {mapIntegerInCentsToCurrency(invoice.totalInCents)}</strong>
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                {!_.isEmpty(disclaimer) &&
                    <Grid item xs={12}>
                        <Box marginY={4}>
                            <Typography variant="body2" align="justify">
                                {disclaimer}
                            </Typography>
                        </Box>
                    </Grid>
                }

                <Grid item xs={12}>
                    <Box alignItems="center" display="flex" justifyContent="center">
                        <Button
                            color="primary"
                            disableElevation
                            onClick={() => navigate('/')}
                            variant="text"
                        >
                            voltar ao painel
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}