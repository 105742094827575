import { nuvemshopService as nps } from './mobilizei/nuvemshop.service';
import { trayService as tps } from './mobilizei/tray.service';
import { productsService as wps } from './wix/products.service';
import { mapNuvemProduct, mapNuvemProductArrayWithVariants } from '../utils/mappers/nuvemshop';
import { mapTrayProduct, mapTrayProductArray } from '../utils/mappers/tray';
import { mapWixProductArrayWithVariants, mapWixProduct } from '../utils/mappers/wix'
import _ from 'lodash';
import { PLATFORMS } from '../utils/constants';

export const productsService = {
    getProducts,
    getProductById,
    searchProducts
};

function getProducts(platformId, storeId, options, accessToken) {
    let queryMethod = null;
    let mapperMethod = null;
    if (platformId === PLATFORMS.NUVEMSHOP) {
        queryMethod = nps.getProducts;
        mapperMethod = mapNuvemProductArrayWithVariants;
    } else if (platformId === PLATFORMS.TRAY ) {
        queryMethod = tps.getProducts;
        mapperMethod = mapTrayProductArray;
    } else {
        queryMethod = wps.getProducts;
        mapperMethod = mapWixProductArrayWithVariants;
    }

    return queryMethod(storeId, options, accessToken).then(response => {
        let data = null;
        
        if (platformId === PLATFORMS.NUVEMSHOP) {
            data = {
                products: mapperMethod(response.data),
                totalCount: response.headers.get('x-total-count')
            }
        } else if(platformId === PLATFORMS.TRAY) {
            data = {
                products: mapperMethod(response.Products),
                totalCount: _.get(response, 'paging.total', 0)
            }
        } else {
            data = {
                products: mapperMethod(response.products),
                totalCount: _.get(response, 'totalResults', 0)
            }
        }

        return data;
    })
}

function getProductById(platformId, storeId, productId, accessToken) {
    let queryMethod = null;
    let mapperMethod = null;
    if (platformId === PLATFORMS.NUVEMSHOP) {
        queryMethod = nps.getProductById;
        mapperMethod = mapNuvemProduct;
    } else if(platformId === PLATFORMS.TRAY) {
        queryMethod = tps.getProductById;
        mapperMethod = mapTrayProduct;
    } else {
        queryMethod = wps.getProductById;
        mapperMethod = mapWixProduct;
    }

    return queryMethod(storeId, productId, accessToken).then(response => {
        const data = PLATFORMS.WIX 
            ? mapperMethod(response.product)
            : mapperMethod(response)

        return data
    })
}

function searchProducts(platformId, storeId, options, accessToken) {
    let queryMethod = null;
    let mapperMethod = null;
    if (platformId === PLATFORMS.NUVEMSHOP) {
        queryMethod = nps.searchProducts;
        mapperMethod = mapNuvemProductArrayWithVariants;
    } else if(platformId === PLATFORMS.TRAY) {
        queryMethod = tps.searchProducts;
        mapperMethod = mapTrayProductArray;
    } else {
        queryMethod = wps.getProducts;
        mapperMethod = mapWixProductArrayWithVariants;
    }

    return queryMethod(storeId, options, accessToken).then(response => {
        let data = null;
        if (platformId === PLATFORMS.NUVEMSHOP) {
            data = {
                products: mapperMethod(response.data),
                totalCount: response.headers.get('x-total-count')
            }
        } else if(platformId === PLATFORMS.TRAY) {
            data = {
                products: mapperMethod(response.Products),
                totalCount: _.get(response, 'paging.total', 0)
            }
        } else {
            data = {
                products: mapperMethod(response.products),
                totalCount: _.get(response, 'totalResults', 0)
            }
        }

        return data;
    })
}