import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useUser } from '../../../contexts/user.context';
import config from '../../../utils/config';
import { APP_AUTH_TOKEN, appPublishStatus, mobileAppPlatforms } from '../../../utils/constants';
import { Box } from '@mui/material';
import { useNavigate } from "react-router-dom";
import DrawerMenu from '../../../layouts/Dashboard/DrawerMenu';
import { mobilizeiStoresService } from '../../../services/mobilizei/stores.service';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
    iframe: {
        border: 0,
        height: '100%',
        width: '100%'
    }
}));

export default function Editor() {
    const navigate = useNavigate();
    const classes = useStyles();
    const { user, setUser } = useUser();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
    useEffect(() => {
      const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleWindowResize);
  
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }, []);

    useEffect(() => {
        // Set the event handler that will listen to messages
        // sent by the iframe
        const handler = event => {
            if (_.isString(event?.data) && event?.data?.includes('missing_feature_save')) {
                let data = {}

                if (event.data.includes('android')) {
                    mobilizeiStoresService.setMobileAppPublishStatus(
                        user?.store?.platformId,
                        user?.store?.id,
                        mobileAppPlatforms.ANDROID,
                        appPublishStatus.PENDING_UPDATE
                    )

                    _.set(data, `platforms.android.publishStatus`, appPublishStatus.PENDING_UPDATE);
                }

                if (event.data.includes('ios')) {
                    mobilizeiStoresService.setMobileAppPublishStatus(
                        user?.store?.platformId,
                        user?.store?.id,
                        mobileAppPlatforms.IOS,
                        appPublishStatus.PENDING_UPDATE
                    )

                    _.set(data, `platforms.ios.publishStatus`, appPublishStatus.PENDING_UPDATE);
                }

                // Update the local user data to match the data that was 
                // updated on the backend
                setUser({ ...user, store: _.merge(user.store, data) });

                // Go to dashboard
                navigate('/');
            }
        }

        window.addEventListener("message", handler)

        // Remove the event listener to clean up
        return () => window.removeEventListener("message", handler)
    }, [])

    return (
        <Box sx={{ display: 'flex', position: 'relative', overflowY: 'hidden' }}>
            <DrawerMenu />
            <Box 
                component="main" 
                marginTop={windowWidth < 600 ? '10vh' : '0'} 
                height={windowWidth < 600 ? '90vh' : '100vh'}
                width='100%' 
                overflowY='hidden'
            >
                <iframe
                    className={classes.iframe}
                    src={`${config.MOBILIZEI_LAYOUT_EDITOR_URL}/?at=${localStorage.getItem(APP_AUTH_TOKEN)}`}
                />
            </Box>
        </Box>
    )
}