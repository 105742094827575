import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { MbControlledMaskedTextField, MbTextField } from '../../../../components/MbTextFields';

export default function Signup({ control, errors, register }) {
    return (
        <Box mb={6}>
            <Typography variant="h5" mb={2}>
                Seus dados
            </Typography>

            <Grid
                container
                component="form"
                spacing={2}
                direction={'row'}
            >
                <Grid item xs={12} sm={12}>
                    <MbTextField
                        error={errors.name?.message}
                        label="Nome completo"
                        register={register("name", {
                            required: "O nome completo é obrigatório."
                        })}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <MbTextField
                        error={errors.email?.message}
                        label="E-mail"
                        helper="Esse é o e-mail utilizado para acessar sua conta."
                        register={register("email", {
                            required: "O e-mail é obrigatório.",
                            pattern: {
                                value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}|s$/,
                                message: "O e-mail não é válido."
                            }
                        })}
                        type="email"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <MbControlledMaskedTextField
                        control={control}
                        error={errors.phoneNumber?.message}
                        format={"(##) #####-####"}
                        label="Telefone"
                        name="phoneNumber"
                    />
                </Grid>
            </Grid>
        </Box>
    );
}