import React, { useState } from 'react';
import { Box, Button, CircularProgress, Grid } from '@mui/material';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { MbTextField, MbControlledMaskedTextField } from './MbTextFields';
import MbSelectField from './MbSelectField';
import { postalCodeService } from '../services/postalCode.service';
import _ from 'lodash';

export default function BillingDataForm({ control, register, watch, errors, loading, setValue,
        primaryButtonLabel, primaryButtonAction, primaryButtonId, secondaryButtonLabel, secondaryButtonAction }) {
    const [loadingAddress, setLoadingAddress] = useState(false);

    const handleZipCodeChange = (e) => {
        setLoadingAddress(true);

        postalCodeService.getAddressByPostalCode(e.target.value)
            .then(response => {
                setValue('street', response['logradouro'])
                setValue('city', response['localidade'])
                setValue('state', response['uf'])
                setValue('district', response['bairro'])
            })
            .catch(() => null)
            .finally(() => setLoadingAddress(false));
    }

    return (
        <form id="user_data_form">
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} >
                    <MbTextField
                        error={errors.name?.message}
                        label="Nome completo ou razão social:"
                        register={register('name', {
                            required: "Insira o nome completo ou a razão social."
                        })}
                    />
                </Grid>

                <Grid item xs={6}>
                    <MbControlledMaskedTextField
                        control={control}
                        error={errors.cpfCnpj?.message}
                        label="CPF ou CNPJ:"
                        format={watch('cpfCnpj')?.length < 12 ? "###.###.###-#####" : "##.###.###/####-##"}
                        mask={" "}
                        name="cpfCnpj"
                        rules={{ 
                            required: "Insira o CPF ou o CNPJ.",
                            validate: value => cpf.isValid(value) || cnpj.isValid(value) ? true : "Insira um número válido."
                        }}
                    />
                </Grid>

                <Grid item xs={6}>
                    <MbControlledMaskedTextField
                        control={control}
                        error={errors.phone?.message}
                        label="Celular:"
                        format={"(##) #####-####"}
                        name="phone"
                        rules={{ required: "O celular é obrigatório." }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <MbTextField
                        error={errors.email?.message}
                        label="E-mail:"
                        helper="As comunicações sobre sua assinatura serão enviadas para este e-mail."
                        register={register("email", {
                            required: "O e-mail é obrigatório.",
                            pattern: {
                                value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}|s$/,
                                message: "O e-mail não é válido."
                            }
                        })}
                        type="email"
                    />
                </Grid>

                <Grid item xs={6}>
                    <MbControlledMaskedTextField
                        control={control}
                        error={errors.zipCode?.message}
                        label="CEP:"
                        format={"#####-###"}
                        name="zipCode"
                        rules={{ required: "O CEP é obrigatório." }}
                        onBlur={handleZipCodeChange}
                    />
                </Grid>

                {loadingAddress &&
                    <Grid item xs={6} style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <CircularProgress color="primary" />
                    </Grid>
                }

                <Grid item xs={9}>
                    <MbTextField
                        error={errors.street?.message}
                        label="Endereço:"
                        register={register('street', {
                            required: "O endereço é obrigatório."
                        })}
                    />
                </Grid>

                <Grid item xs={3}>
                    <MbTextField
                        error={errors.number?.message}
                        label="Número:"
                        register={register('number', {
                            required: "O número é obrigatório."
                        })}
                    />
                </Grid>

                <Grid item xs={9}>
                    <MbTextField
                        error={errors.city?.message}
                        label="Cidade:"
                        register={register('city', {
                            required: "A cidade é obrigatória."
                        })}
                    />
                </Grid>

                <Grid item xs={3}>
                    <MbSelectField
                        error={errors.state?.message}
                        label="Estado:"
                        name="state"
                        register={register('state', {
                            required: 'O estado é obrigatório.'
                        })}
                    >
                        <option value=""></option>
                        <option value="AC">AC</option>
                        <option value="AL">AL</option>
                        <option value="AP">AP</option>
                        <option value="AM">AM</option>
                        <option value="BA">BA</option>
                        <option value="CE">CE</option>
                        <option value="DF">DF</option>
                        <option value="ES">ES</option>
                        <option value="GO">GO</option>
                        <option value="MA">MA</option>
                        <option value="MS">MS</option>
                        <option value="MT">MT</option>
                        <option value="MG">MG</option>
                        <option value="PA">PA</option>
                        <option value="PB">PB</option>
                        <option value="PR">PR</option>
                        <option value="PE">PE</option>
                        <option value="PI">PI</option>
                        <option value="RJ">RJ</option>
                        <option value="RN">RN</option>
                        <option value="RS">RS</option>
                        <option value="RO">RO</option>
                        <option value="RR">RR</option>
                        <option value="SC">SC</option>
                        <option value="SP">SP</option>
                        <option value="SE">SE</option>
                        <option value="TO">TO</option>
                    </MbSelectField>
                </Grid>

                <Grid item xs={6}>
                    <MbTextField
                        error={errors.district?.message}
                        label="Bairro:"
                        register={register('district', {
                            required: "O bairro é obrigatório."
                        })}
                    />
                </Grid>

                <Grid item xs={6}>
                    <MbTextField
                        label="Complemento:"
                        register={register('complement', { required: false })}
                    />
                </Grid>
            </Grid>

            {loading ? (
                <Box alignItems="center" display="flex" justifyContent="center" mt={8}>
                    <CircularProgress color="primary" />
                </Box>
            ) : (
                <Box alignItems="center" display="flex" justifyContent="center" mt={8}>
                    {!_.isEmpty(secondaryButtonLabel) &&
                        <Button color="primary" style={{ marginRight: 50 }} onClick={secondaryButtonAction}>
                            {secondaryButtonLabel}
                        </Button>
                    }

                    {!_.isEmpty(primaryButtonLabel) &&
                        <Button 
                            id={primaryButtonId} 
                            variant="contained" 
                            color="primary" 
                            disableElevation
                            onClick={primaryButtonAction}
                        >
                            {primaryButtonLabel}
                        </Button>
                    }
                </Box>
            )}
        </form>
    );
}