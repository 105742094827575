import { APP_UTMS } from "./constants";

export const saveUtmsToLocalStorage = () => {
    const urlParams = new URLSearchParams(window.location.search);
    let utms = {};

    const utmKeys = ["utm_source", "utm_medium", "utm_campaign", "utm_content", "utm_term"];
    utmKeys.forEach(key => {
        const utmValue = urlParams.get(key);
        if (utmValue) {
            utms[key] = utmValue;
        }
    });

    localStorage.setItem(APP_UTMS, JSON.stringify(utms));
}