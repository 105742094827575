import React from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useOnboarding } from '../../../contexts/onboarding.context';

export default function Start() {
    const { goToNextStep } = useOnboarding();

    return (
        <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Typography component="h1" variant="h2" align='center'>
                Vamos criar o aplicativo da sua loja
            </Typography>

            <Grid
                container
                maxWidth={"sm"}
                direction={'row'}
                justifyContent={'center'}
            >
                <Grid item xs={12} justifyContent='center'>
                    <Typography sx={{ my: 4 }} component="h2" variant="h5" align='center'>
                        Responda algumas perguntas para criar o seu aplicativo.
                    </Typography>
                </Grid>

                <Grid item xs={6} sm={4}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3 }}
                        onClick={goToNextStep}
                    >
                        Começar
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}