import React, { useEffect, useState } from 'react';
import { mobilizeiInvoicesService } from '../../../services/mobilizei/invoices.service';
import MainLayout from '../../../layouts/main';
import { CircularProgress, Container, Grid, Typography } from '@mui/material';
import Header from './Header';
import CustomerData from './CustomerData';
import BillingDetails from './BillingDetails';
import PaymentMethods from './PaymentMethods';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';

export default function Invoice() {
    const { invoiceId } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [invoice, setInvoice] = useState({});

    useEffect(() => {
        if(!invoiceId) {
            navigate('/');
            return;
        }

        mobilizeiInvoicesService.getInvoiceById(invoiceId)
            .then(invoiceData => {
                setInvoice(invoiceData);
                setLoading(false);
            })
            .catch(e => {
                setInvoice({});
                setLoading(false);
            })
    }, []);

    return (
        <MainLayout>
            <Container maxWidth="sm">
                {loading ? (
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{ minHeight: '60vh' }}
                    >
                        <Grid item xs={4}>
                            <CircularProgress color="primary" />
                        </Grid>
                    </Grid>
                ) : (
                    <>
                        {_.isEmpty(invoice) ? (
                            <Grid
                                container
                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                style={{ minHeight: '60vh' }}
                            >
                                <Grid item xs={12}>
                                    <Typography align="center" variant="h4">
                                        Fatura inexistente
                                    </Typography>
                                </Grid>
                            </Grid>
                        ) : (
                            <>
                                <Header invoice={invoice} />
                                <CustomerData invoice={invoice} />
                                <BillingDetails invoice={invoice} />
                                {invoice.status === 'pending' && <PaymentMethods invoice={invoice} setInvoice={setInvoice} />}
                            </>
                        )}
                    </>
                )}
            </Container>
        </MainLayout >
    );
}