import React, { useState } from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { MbTextField } from '../../../components/MbTextFields';
import { useOnboarding } from '../../../contexts/onboarding.context';
import { mobilizeiUsersService } from '../../../services/mobilizei/users.service';
import { showApiError } from '../../../utils/errors';
import { CircularProgress } from '@mui/material';
import mixpanel from 'mixpanel-browser';
import { mixpanelEvents } from '../../../utils/constants';

export default function StoreUrl() {
    const { handleSubmit, register, formState: { errors } } = useForm();
    const { data, setData, goToNextStep } = useOnboarding();

    const [isLoading, setIsLoading] = useState(false)

    const onSubmit = (form) => {
        setIsLoading(true)

        let storeUrl = form.storeUrl;

        // Check if the url starts with http. If not, add it.
        if (!storeUrl.startsWith('http')) {
            storeUrl = 'https://' + storeUrl;
        }

        // Remove trailing slashs from url
        storeUrl = storeUrl.replace(/\/+$/g, '');

        mobilizeiUsersService.checkStoreUrl(data.ecommercePlatformId, storeUrl)
            .then(response => {
                setData({
                    ...data,
                    storeAccountUrl: storeUrl + data.storeAccountUrl,
                    storeCartUrl: storeUrl + data.storeCartUrl,
                    storeId: response.storeId,
                    storeUrl
                })

                // Track event to Mixpanel
                mixpanel.track(
                    mixpanelEvents.ONBOARDING_STORE_URL_INSERTED,
                    { store_url: storeUrl }
                )

                goToNextStep();

            })
            .catch(e => {
                showApiError(e.error);
                setIsLoading(false)
            })
    }

    return (
        <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Typography component="h1" variant="h4" align='center' fontWeight='bold'>
                Qual é a url da sua loja?
            </Typography>

            <Grid
                container
                component="form"
                spacing={2}
                maxWidth={"sm"}
                direction={'row'}
                justifyContent={'center'}
                sx={{ mt: 6 }}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid item xs={12} sm={9}>
                    <MbTextField
                        error={errors.storeUrl?.message}
                        placeholder="Ex.: https://www.minhaloja.com.br"
                        register={register("storeUrl", {
                            validate: (value) => {
                                if (!value.includes('http')) {
                                    value = 'https://' + value;
                                }

                                if (value.includes('@'))
                                    return 'Parece que você digitou um e-mail! Por favor, insira a url utilizada para acessar a sua loja. Ex.: https://www.minhaloja.com.br'

                                // return isUrl(value) || "Parece que tem algo de errado com esta url! Por favor, verifique se esta é mesmo a url da sua loja."
                            }
                        })}
                    />
                </Grid>

                {isLoading ? (
                    <Grid
                        container
                        justifyContent='center'
                        sx={{ mt: 6 }}
                    >
                        <CircularProgress />
                    </Grid>
                ) : (
                    <Grid item xs={6} sm={4}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 6, mb: 10 }}
                        >
                            Próximo
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
}