import { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/Dashboard";
import EditIcon from '@mui/icons-material/Edit';
import { MbTextField } from '../../../components/MbTextFields';
import { useForm } from 'react-hook-form';
import { Grid } from "@mui/material";
import MbTextArea from "../../../components/MbTextArea";
import { useUser } from "../../../contexts/user.context";
import SaveButton from "../../../components/SaveButton";
import { mobilizeiStoresService } from '../../../services/mobilizei/stores.service';
import { toast } from 'react-toastify';
import { showApiError } from "../../../utils/errors";
import useAppInfo from '../../../hooks/appInfo.hook';
import { mobileAppPlatforms } from "../../../utils/constants";
import PublishingWarning from "../../../components/PublishingWarning";
import PublishRequiredDialog from "../../../components/PublishRequiredDialog";
import _ from 'lodash';
import { useNavigate } from "react-router-dom";

export default function General() {
    const navigate = useNavigate();

    const { isAppBeingPublished, isAppPublished, setPendingUpdateStatusOnStoreData } = useAppInfo();
    const { user, setUser } = useUser();

    const [isEditionDisabled, setIsEditionDisabled] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [showPublishRequiredDialog, setShowPublishRequiredDialog] = useState(false);

    const { getValues, handleSubmit, register, reset, watch, formState: { errors, isDirty } } = useForm({
        defaultValues: {
            title: user?.store?.title || '',
            shortDescription: user?.store?.shortDescription || '',
            description: user?.store?.description || '',
            privacyPolicyUrl: user?.store?.privacyPolicyUrl || '',
            keywords: user?.store?.keywords || '',
            promotionalVideo: user?.store?.promotionalVideo || '',
        }
    });

    useEffect(() => {
        setIsEditionDisabled(isAppBeingPublished(mobileAppPlatforms.ANDROID) || isAppBeingPublished(mobileAppPlatforms.IOS))
    }, [])

    const onSubmit = () => {
        if (isAppPublished()) {
            setShowPublishRequiredDialog(true);
        } else {
            onSave();
        }
    }

    const onSave = () => {
        let data = getValues();

        // If the app is published, we need to change its status to pending update
        if (isAppPublished()) {
            data = setPendingUpdateStatusOnStoreData(data)
        }

        setIsLoading(true);
        setShowPublishRequiredDialog(false);

        mobilizeiStoresService.updateStoreById(user?.store?.platformId, user?.store?.id, data)
            .then(() => {
                toast.success('Alterações salvas com sucesso!');

                // Update the local user data to match the data that was 
                // updated on the backend
                setUser({ ...user, store: _.merge(user.store, data) });

                // Calling the reset function with an object will update the form defaultValues 
                // and subsequent calls to reset() will use the new default values
                reset(data);

                // If the app is published, we redirect the user to the dashboard so he can
                // see the pending publishing warning
                if (isAppPublished()) {
                    navigate('/')
                }
            })
            .catch(e => {
                showApiError(e.error);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <DashboardLayout
            Icon={EditIcon}
            title="Dados gerais"
            subtitle="Informações de marketing exibidas nas lojas do Google e da Apple"
        >
            <PublishingWarning />

            <PublishRequiredDialog
                open={showPublishRequiredDialog}
                handleClose={() => {
                    setShowPublishRequiredDialog(false);
                    reset();
                }}
                handleSave={() => onSave()}
            />

            <Grid
                container
                component="form"
                spacing={2}
                direction={'row'}
                justifyContent={'center'}
                sx={{ mt: 2, mb: { xs: 18, md: 14 }, }}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid item xs={12}>
                    <MbTextField
                        disabled={isEditionDisabled}
                        error={errors.title?.message}
                        helper={`O título do seu aplicativo da forma como ele vai aparecer nas lojas do Google e da Apple. Títulos com todas as letras maiúsculas não são aceitos pelo Google e serão alterados no momento da publicação do aplicativo. Caracteres restantes: ${30 - watch('title', '').length}`}
                        maxLength="30"
                        label="Título *"
                        register={register("title", {
                            required: "O título é obrigatório."
                        })}
                    />
                </Grid>

                <Grid item xs={12}>
                    <MbTextField
                        disabled={isEditionDisabled}
                        error={errors.shortDescription?.message}
                        helper={`Descreva de forma resumida qual é o objetivo do seu aplicativo. Caracteres restantes: ${80 - watch('shortDescription', '').length}`}
                        maxLength="80"
                        label="Descrição curta *"
                        register={register("shortDescription", {
                            required: "A descrição curta é obrigatória."
                        })}
                    />
                </Grid>

                <Grid item xs={12}>
                    <MbTextArea
                        disabled={isEditionDisabled}
                        error={errors.description?.message}
                        helper={`Capriche na descrição, faça um bom marketing e convença os usuários a baixarem o seu aplicativo. Esta descrição detalhada será exibida na tela de download do app. Caracteres restantes: ${4000 - watch('description', '').length}`}
                        maxLength="4000"
                        label="Descrição detalhada *"
                        register={register("description", {
                            required: "A descrição detalhada é obrigatória."
                        })}
                        rows="5"
                    />
                </Grid>

                <Grid item xs={12}>
                    <MbTextField
                        disabled={isEditionDisabled}
                        error={errors.privacyPolicyUrl?.message}
                        helper="Nas lojas do Google e da Apple é necessário incluir o link para a política de tratamento de dados dos clientes da sua loja. Normalmente, este documento tem o nome de Política de Privacidade, Política de Segurança ou Termos de Uso. Caso você não insira o link desta política, a publicação do seu aplicativo pode ser negada pelo Google ou pela Apple."
                        maxLength="500"
                        placeholder="Ex.: https://www.minhaloja.com.br/privacidade"
                        label="Url da Política de Privacidade *"
                        register={register("privacyPolicyUrl", {
                            required: "A url da Política de Privacidade é obrigatória."
                        })}
                    />
                </Grid>

                <Grid item xs={12}>
                    <MbTextField
                        disabled={isEditionDisabled}
                        error={errors.keywords?.message}
                        helper={`As palavras chave facilitam com que o seu aplicativo seja encontrado pelos clientes. Coloque todos os principais termos relacionados ao seu negócio separados por vírgula. Caracteres restantes: ${100 - watch('keywords', '').length}`}
                        maxLength="100"
                        placeholder="Ex.: roupas, camisas, calças, moda"
                        label="Palavras chave *"
                        register={register("keywords", {
                            required: "As palavras chave são obrigatórias."
                        })}
                    />
                </Grid>

                <Grid item xs={12}>
                    <MbTextField
                        disabled={isEditionDisabled}
                        error={errors.promotionalVideo?.message}
                        helper="A loja de aplicativos do Android (Play Store) permite a inserção de um vídeo promocional que será exibido na tela de download do seu app. Este vídeo deve estar publicado no YouTube e a sua inserção é opcional."
                        maxLength="500"
                        placeholder="Ex.: https://www.youtube.com/watch?v=XXXXXXXXXXX"
                        label="Vídeo promocional"
                        register={register("promotionalVideo")}
                    />
                </Grid>

                <SaveButton
                    isLoading={isLoading}
                    onCancel={() => reset()}
                    show={isDirty}
                />
            </Grid>
        </DashboardLayout>
    )
}