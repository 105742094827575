import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, CircularProgress, Typography } from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { mobilizeiStoresService } from '../services/mobilizei/stores.service';
import { useUser } from "../contexts/user.context";
import { showApiError } from '../utils/errors';
import { toast } from 'react-toastify';
import { formatFileName } from '../utils/format';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '1.6rem',
    textAlign: 'left',
    width: '100%'
  },
  hidden: {
    display: 'none'
  },
  loadingWrapper: {
    padding: '20px 0'
  },
  imageWrapper: {
    alignItems: 'center',
    backgroundColor: '#ededed',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12);',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: '100%'
  },
  image: {
    boxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)',
    height: 'auto',
    margin: 10,
    width: '25%'
  },
  imageTitle: {
    display: "block",
    marginBottom: 5,
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: 1.8,
    color: "#000",
    textAlign: 'left'
  },
  deleteWrapper: {
    cursor: 'pointer',
    position: 'absolute',
    top: 5,
    right: 5
  },
  button: {
    borderRadius: 0,
    width: '100%'
  }
}));

export default function ImageUploaderBlock(props) {
  const { user } = useUser();

  const fileInputRef = useRef(null);

  const classes = useStyles({ imageWidth: props.imageWidth });

  const [isLoading, setIsLoading] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(props.imageUrl);

  useEffect(() => {
    setUploadedImageUrl(props.imageUrl);
  }, [props.imageUrl]);

  function handleUploadChange(e) {
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    if (file.size / 1000 >= props.maxFileSizeInKB) {
      toast.error(`A imagem deve ter no máximo ${props.maxFileSizeInKB} KB`)
    } else {
      setIsLoading(true);

      let img = new Image();
      const _URL = window.URL || window.webkitURL;

      img.src = _URL.createObjectURL(file);
      img.onload = function () {
        let imageData = new FormData();
        imageData.append("image", file, formatFileName(file.name));

        mobilizeiStoresService.uploadFile(
          user?.store?.platformId,
          user?.store?.id,
          imageData
        )
          .then((response) => {
            onUploadSuccess(response?.fileUrl, img.height, img.width)
          })
          .catch(e => {
            showApiError(e.error);
          })
          .finally(() => {
            setIsLoading(false);
          })
      }
    }
  }

  function onUploadSuccess(downloadURL, height, width) {
    setIsLoading(false);
    setUploadedImageUrl(downloadURL);

    props.onUploadSuccess({
      height,
      url: downloadURL,
      width
    });
  }

  function onDelete() {
    setUploadedImageUrl(null);
    fileInputRef.current.value = null;

    if (props.onDeleteSuccess) {
      props.onDeleteSuccess();
    }
  }

  return (
    <div className={classes.container}>
      <input
        accept="image/*"
        className={classes.hidden}
        onChange={handleUploadChange}
        ref={fileInputRef}
        type="file"
      />

      <label className={classes.imageTitle}>{props.label}</label>

      <div>
        <div className={classes.imageWrapper}>
          {isLoading ?
            <div className={classes.loadingWrapper}>
              <CircularProgress />
            </div> : (
              uploadedImageUrl &&
              <>
                <img
                  alt=""
                  className={classes.image}
                  src={uploadedImageUrl}
                />
                <div
                  className={classes.deleteWrapper}
                  onClick={() => onDelete()}
                >
                  <HighlightOffIcon />
                </div>
              </>
            )}
        </div>

        <Button
          className={classes.button}
          onClick={() => fileInputRef.current.click()}
          variant="outlined"
          color="secondary"
        >
          selecionar imagem
        </Button>
      </div>

      <Typography variant="caption">{props.helperText}</Typography>
    </div>
  );
}