import { useUser } from '../contexts/user.context';
import _ from 'lodash';
import { formatTimestamp } from '../utils/format';
import moment from 'moment';

export default function useAppInfo() {
    const { user } = useUser()
    const store = _.get(user, 'store', {});

    const appInfo = {
        accessToken: store.accessToken,
        iconUrl: store.iconUrl,
        platformId: store.platformId,
        storeId: store.id,
        storeStatus: store.status,
        title: store.title,
        trialEndsAt: store.trialEndsAt,
        androidPublishStatus: _.get(store, 'platforms.android.publishStatus', 'first_publish_pending'),
        iOSPublishStatus: _.get(store, 'platforms.ios.publishStatus', 'first_publish_pending'),
        androidDownloadUrl: _.get(store, 'platforms.android.downloadUrl', ''),
        iOSDownloadUrl: _.get(store, 'platforms.ios.downloadUrl', ''),
        androidLastPublishRequestedAt: formatTimestamp(_.get(store, 'platforms.android.lastPublishRequestedAt', ''), 'DD/MM/YYYY'),
        iOSLastPublishRequestedAt: formatTimestamp(_.get(store, 'platforms.ios.lastPublishRequestedAt', ''), 'DD/MM/YYYY')
    }

    const isAppPublished = () => {
        if (!_.isEmpty(appInfo.androidDownloadUrl) || !_.isEmpty(appInfo.iOSDownloadUrl)) {
            return true;
        }

        return false;
    }

    const isBothAppVersionsPublished = () => {
        if (!_.isEmpty(appInfo.androidDownloadUrl) && !_.isEmpty(appInfo.iOSDownloadUrl)) {
            return true;
        }

        return false;
    }

    const isAndroidAppPublished = () => {
        if (!_.isEmpty(appInfo.androidDownloadUrl)) {
            return true;
        }

        return false;
    }

    const isiOSAppPublished = () => {
        if (!_.isEmpty(appInfo.iOSDownloadUrl)) {
            return true;
        }

        return false;
    }

    const isFirstPublishPending = () => {
        if (appInfo.androidPublishStatus === 'first_publish_pending' && appInfo.iOSPublishStatus === 'first_publish_pending') {
            return true;
        };

        return false
    }

    const isAppBeingPublished = (platform) => {
        let currentPublishStatus = '';
        if (platform === 'android') {
            currentPublishStatus = appInfo.androidPublishStatus;
        } else {
            currentPublishStatus = appInfo.iOSPublishStatus;
        }

        if (
            currentPublishStatus === 'pending_listing_creation' ||
            currentPublishStatus === 'publishing' ||
            currentPublishStatus === 'in_review'
        ) {
            return true;
        }

        return false;
    }

    const setPendingUpdateStatusOnStoreData = (settings, setOnAndroid = true, setOniOS = true) => {
        let settingsCopy = JSON.parse(JSON.stringify(settings));

        if(setOnAndroid && appInfo.androidPublishStatus === 'published') {
            _.set(settingsCopy, 'platforms.android.publishStatus', 'pending_update');
        }

        if(setOniOS && appInfo.iOSPublishStatus === 'published') {
            _.set(settingsCopy, 'platforms.ios.publishStatus', 'pending_update');
        }

        return settingsCopy;
    }

    const showPricingOnPublishSection = () => {
        if (appInfo.storeStatus !== 'active') {
            return true;
        }

        return false;
    }

    const getTrialDaysLeft = () => {
        if (!appInfo.trialEndsAt) {
            return 0;
        }

        const momentDate = moment(new Date(appInfo.trialEndsAt));
        const now = moment();
        let trialDaysLeft = momentDate.diff(now, 'days');

        trialDaysLeft += 1;

        return trialDaysLeft < 0 ? 0 : trialDaysLeft;
    }

    return {
        appInfo,
        isAppPublished,
        isBothAppVersionsPublished,
        isAndroidAppPublished,
        isiOSAppPublished,
        isFirstPublishPending,
        isAppBeingPublished,
        setPendingUpdateStatusOnStoreData,
        showPricingOnPublishSection,
        getTrialDaysLeft
    }
}
