import React, { useEffect } from 'react';
import _ from 'lodash';
import Box from '@mui/material/Box';
import { PLATFORMS, planIds, platformsIntegration } from '../../../utils/constants';
import { useUser } from '../../../contexts/user.context';
import TrayIntegration from '../../../components/platformIntegrations/TrayIntegration';
import { useNavigate } from 'react-router-dom';
import { usePlan } from '../../../contexts/plan.context';
import BillingLayout from '../../../layouts/billing';

export default function PlatformIntegration() {
    const { user } = useUser();
    const { plan } = usePlan();
    const navigate = useNavigate();

    useEffect(() => {
        if (!platformsIntegration.includes(user.store?.platformId) || plan != planIds.PLAN_PRO_MONTHLY_2024) {
            navigate('/pricing')
        }
    }, [])

    const renderIntegrationHelp = () => {
        if (platformsIntegration.includes(user.store?.platformId)) {
            if (plan == planIds.PLAN_PRO_MONTHLY_2024) {
                switch (user.store?.platformId) {
                    case PLATFORMS.TRAY:
                        return <TrayIntegration />
                }
            }
        }
    }

    return (
        <BillingLayout fullScreen>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {renderIntegrationHelp()}
            </Box>
        </BillingLayout>
    );
}