import React, { useState } from 'react';
import { Box, Button, Checkbox, Grid, Typography } from "@mui/material";
import _ from "lodash";
import { MbTextField } from '../../../components/MbTextFields';
import { useUser } from "../../../contexts/user.context";
import { useForm } from 'react-hook-form';
import { mobilizeiStoresService } from '../../../services/mobilizei/stores.service';
import CompanyAccountDialog from './CompanyAccountDialog';

export default function Step1({ nextStep }) {
    const { user, setUser } = useUser();

    const [isAccountAgreementChecked, setIsAccountAgreementChecked] = useState(false);
    const [isPartnerAgreementChecked, setIsPartnerAgreementChecked] = useState(false);
    const [isContentAgreenmentChecked, setIsContentAgreenmentChecked] = useState(false);
    const [isTermAgreementChecked, setIsTermAgreementChecked] = useState(false);
    
    const [showCompanyAccountDialog, setShowCompanyAccountDialog] = useState(false);

    const { getValues, handleSubmit, register, formState: { errors } } = useForm({
        defaultValues: {
            developerAccountEmail: user?.store?.platforms?.android?.developerAccountEmail,
            developerAccountName: user?.store?.platforms?.android?.developerAccountName
        }
    });

    function onSubmit() {
        setShowCompanyAccountDialog(true)
    }

    function goToNextStep() {
        let data = getValues();

        if (data.developerAccountEmail) {
            data.developerAccountEmail = data.developerAccountEmail.trim()
        }

        // Save the developerAccountEmail and the developerAccountName
        mobilizeiStoresService
            .updateStoreById(user?.store?.platformId, user?.store?.id, {
                platforms: {
                    android: {
                        developerAccountEmail: data.developerAccountEmail,
                        developerAccountName: data.developerAccountName
                    }
                }
            }).catch(() => null)

        // Set the local user properties
        setUser({
            ...user,
            store: {
                ...user?.store,
                platforms: {
                    ...user?.store?.platforms,
                    android: {
                        ...user?.store?.platforms?.android,
                        developerAccountEmail: data.developerAccountEmail,
                        developerAccountName: data.developerAccountName
                    }
                }
            }
        })

        nextStep()
    }

    return (
        <Box sx={{ paddingX: 2, paddingY: 4 }}>
            <Typography align="justify" variant="subtitle1" sx={{ mb: 3 }}>
                Para que você possa publicar seu aplicativo Android é necessário que você crie uma conta de desenvolvedor do Google Play do tipo “Organização”. A criação dessa conta tem uma <strong>taxa única de registro de U$25 (vinte e cinco dólares)</strong> que deve ser paga diretamente para o Google.
            </Typography>

            <Typography align="justify" variant="subtitle1" sx={{ mb: 3 }}>
                Caso você ainda não tenha uma conta de desenvolvedor do Google Play organizacional, você pode seguir <a href="https://mobilizei.com.br/tutoriais/como-criar-conta-desenvolvedor-google-play" target="_blank">este tutorial</a> no qual descrevemos todos os passos necessários para criação desta conta.
            </Typography>

            <Typography align="justify" variant="subtitle1" sx={{ mb: 3 }}>
                Com a sua conta organizacional criada, você terá que nos conceder um acesso à ela. <a href="https://mobilizei.com.br/tutoriais/convidando-a-mobilizei-para-sua-conta-google-play" target="_blank">Clique aqui</a> para verificar como você pode nos conceder este acesso.
            </Typography>

            <Typography align="justify" variant="subtitle1" sx={{ mb: 3 }}>
                E por último, precisamos saber qual é o email e o nome de desenvolvedor que você utilizou na criação da sua conta:
            </Typography>

            <Grid
                container
                component="form"
                spacing={2}
                direction={'row'}
                justifyContent={'left'}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid item xs={12}>
                    <MbTextField
                        error={errors.developerAccountEmail?.message}
                        maxLength="300"
                        label="Email *"
                        register={register("developerAccountEmail", {
                            required: "O e-mail da conta de desenvolvedor é obrigatório.",
                            pattern: {
                                value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}|s$/,
                                message: "O e-mail não é válido."
                            }
                        })}
                        type="email"
                    />
                </Grid>

                <Grid item xs={12}>
                    <MbTextField
                        error={errors.developerAccountName?.message}
                        maxLength="300"
                        label="Nome de desenvolvedor *"
                        register={register("developerAccountName", {
                            required: "O nome de desenvolvedor é obrigatório."
                        })}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Box display='flex' alignItems='center' marginBottom={1} sx={{ cursor: 'pointer' }} onClick={() => setIsAccountAgreementChecked(!isAccountAgreementChecked)}>
                        <Checkbox checked={isAccountAgreementChecked} sx={{ paddingLeft: 0 }} />
                        <Typography align="justify" variant="subtitle1">
                            Tenho uma conta de desenvolvedor do Google Play organizacional ativa.
                        </Typography>
                    </Box>

                    <Box display='flex' alignItems='center' marginBottom={1} sx={{ cursor: 'pointer' }} onClick={() => setIsPartnerAgreementChecked(!isPartnerAgreementChecked)}>
                        <Checkbox checked={isPartnerAgreementChecked} sx={{ paddingLeft: 0 }} />
                        <Typography align="justify" variant="subtitle1">
                            Concedi um acesso ao email suporte@mobilizei.com.br à minha conta de desenvolvedor do Google Play organizacional.
                        </Typography>
                    </Box>

                    <Box display='flex' alignItems='center' marginBottom={1} sx={{ cursor: 'pointer' }} onClick={() => setIsContentAgreenmentChecked(!isContentAgreenmentChecked)}>
                        <Checkbox checked={isContentAgreenmentChecked} sx={{ paddingLeft: 0 }} />
                        <Typography align="justify" variant="subtitle1">
                            Garanto que os produtos e o conteúdo da minha loja estão de acordo com a <a href="https://support.google.com/googleplay/android-developer/topic/9877466?hl=pt-BR" target="_blank">Política de Conteúdo Restrito da Play Store.</a>
                        </Typography>
                    </Box>

                    <Box display='flex' alignItems='center' marginBottom={1} sx={{ cursor: 'pointer' }} onClick={() => setIsTermAgreementChecked(!isTermAgreementChecked)}>
                        <Checkbox checked={isTermAgreementChecked} sx={{ paddingLeft: 0 }} />
                        <Typography align="justify" variant="subtitle1">
                            Li e concordo com os <a href="https://mobilizei.com.br/termos-de-uso" target="_blank">Termos de Uso</a> da Mobilizei.
                        </Typography>
                    </Box>
                </Grid>

                <Box display='flex' justifyContent='center' marginTop={4} width='100%'>
                    <Button disabled={!isAccountAgreementChecked || !isPartnerAgreementChecked || !isContentAgreenmentChecked || !isTermAgreementChecked} variant="contained" type="submit">
                        próximo
                    </Button>
                </Box>
            </Grid>

            <CompanyAccountDialog
                open={showCompanyAccountDialog}
                handleClose={() => setShowCompanyAccountDialog(false)}
                goToNextStep={goToNextStep}
            />
        </Box>
    )
}