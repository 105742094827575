import React, { useState } from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import config from '../../utils/config';
import image from '../../assets/img/integrationHelps/wixIntegrationHelp.png';
import { CircularProgress } from '@mui/material';

export default function WixIntegration() {
    const [isLoading, setIsLoading] = useState(false)

    const onRedirect = () => {
        setIsLoading(true);
        window.open(config.WIX_AUTH_URL, "_self");
    }

    const platformData = {
        name: "Wix",
        imgUrl: image,
        buttonText: "Aceitar e adicionar"
    }

    return (
        <>
            <Typography component="h1" variant="h4" align='center' fontWeight='bold'>
                Integração com a {platformData.name}
            </Typography>

            <Grid
                container
                direction={'column'}
                justifyContent={'center'}
                gap={2}
                maxWidth={'md'}
                spacing={2}
                sx={{ mt: 6 }}
            >
                <Typography variant="h6" align='center' lineHeight={1.5}>
                    Para continuar a criação do seu aplicativo, é necessário realizar a integração entre a sua loja {platformData.name} e a Mobilizei.
                </Typography>

                <Typography variant="h6" align='center' lineHeight={1.5}>
                    Ao clicar no botão <strong>Integrar</strong>, você será redirecionado para o painel administrativo da sua loja,
                    onde deverá clicar no botão <strong>Aceitar e começar</strong>.
                </Typography>
            </Grid>

            <Grid
                container
                flexDirection={'column'}
                justifyContent={'center'}
                maxWidth={'md'}
                spacing={2}
                sx={{ mt: 4 }}
            >
                <Grid
                    container
                    justifyContent={'center'}
                    maxWidth='md'
                    spacing={2}
                >
                    <img src={platformData.imgUrl} alt='integração' style={{ width: '100%' }} />
                </Grid>

                {isLoading ? (
                    <Grid
                        container
                        justifyContent='center'
                        sx={{ mt: 4, mb: 10 }}
                    >
                        <CircularProgress />
                    </Grid>
                ) : (
                    <Grid
                        container
                        direction={'row'}
                        justifyContent={'center'}
                        maxWidth={'md'}
                        spacing={2}
                    >
                        <Grid item xs={6} sm={2}>
                            <Button
                                disabled={false}
                                fullWidth
                                onClick={onRedirect}
                                type="submit"
                                variant="contained"
                                sx={{ mt: 4, mb: 10 }}
                            >
                                Integrar
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </>
    );
}