import React, { useState } from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useOnboarding } from '../../../contexts/onboarding.context';
import ecommercePlatforms from '../../../utils/ecommercePlatforms';
import EcommercePlatformOption from './ecommercePlatformOption';
import mixpanel from 'mixpanel-browser';
import { mixpanelEvents } from '../../../utils/constants';

export default function EcommercePlatform() {
    const [selectedPlatform, setSelectedPlatform] = useState()
    const { data, setData, goToNextStep } = useOnboarding();

    const onSubmit = () => {
        setData({
            ...data,
            ecommercePlatformId: selectedPlatform.id,
            ecommercePlatformName: selectedPlatform.name,
            ecommercePlatformAllowsIframe: selectedPlatform.isIframeAllowed,
            storeAccountUrl: selectedPlatform.storeAccountUrl,
            storeCartUrl: selectedPlatform.storeCartUrl, 
        })

        // Track event to Mixpanel
        mixpanel.track(
            mixpanelEvents.ONBOARDING_ECOMMERCE_PLATFORM_SELECTED, 
            { ecommerce_platform_id: selectedPlatform.id }
        )

        goToNextStep();
    }

    return (
        <Box
            sx={{
                marginY: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Typography component="h1" variant="h4" align='center' fontWeight='bold'>
                Qual é a sua plataforma de e-commerce?
            </Typography>

            <Grid
                container
                columns={20}
                direction={'row'}
                justifyContent={'flex-start'}
                maxWidth={'md'}
                spacing={2}
                sx={{ mt: 6 }}
            >
                {ecommercePlatforms.getAll().map(ep => (
                    <EcommercePlatformOption
                        data={ep}
                        onClick={() => setSelectedPlatform(ep)}
                        key={ep.id}
                        selected={selectedPlatform?.id === ep.id}
                        shouldFade={selectedPlatform && selectedPlatform?.id !== ep.id}
                    />
                ))}

                <Grid
                    container
                    direction={'row'}
                    justifyContent={'center'}
                    maxWidth={'md'}
                    spacing={2}
                >
                    <Grid item xs={6} sm={2}>
                        <Button
                            disabled={!selectedPlatform}
                            fullWidth
                            onClick={onSubmit}
                            type="submit"
                            variant="contained"
                            sx={{ mt: 6, mb: 10 }}
                        >
                            Próximo
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}