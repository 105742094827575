import React from 'react';
import _ from 'lodash';
import { Typography, Link, Box } from "@mui/material";
import useAppInfo from '../../../hooks/appInfo.hook';
import { useUser } from "../../../contexts/user.context";
import Warnings from "../../../components/publish/Warnings";
import MissingDataCard from "../../../components/publish/MissingDataCard";
import PublishedCard from "../../../components/publish/PublishedCard";
import PublishingCard from "../../../components/publish/PublishingCard";
import WarningCard from "../../../components/publish/WarningCard";
import PublishButton from "../../../components/publish/PublishButton";

export default function Step2(props) {
    const { appInfo, isAppBeingPublished } = useAppInfo();
    const { user } = useUser();

    const publishStatus = appInfo.androidPublishStatus;
    const downloadUrl = appInfo.androidDownloadUrl;

    function isMissingData() {
        return (
            _.isEmpty(user?.store?.title) ||
            _.isEmpty(user?.store?.shortDescription) ||
            _.isEmpty(user?.store?.description) ||
            _.isEmpty(user?.store?.privacyPolicyUrl) ||
            _.isEmpty(user?.store?.keywords) ||
            _.isEmpty(user?.store?.launchScreenUrl) ||
            _.isEmpty(user?.store?.iconUrl) ||
            _.isEmpty(user?.store?.featureGraphicUrl)
        )
    }

    return (
        <Box sx={{ paddingX: 2, paddingY: 4 }}>
            {_.isEmpty(downloadUrl) && (
                <Typography align="justify" variant="subtitle1" sx={{ mb: 3 }}>
                    Fez todas as configurações necessárias e está pronto para disponibilizar a versão Android do seu aplicativo para os seus clientes? Clique no botão “publicar” para enviar o aplicativo para o processo de aprovação da Play Store.
                </Typography>
            )}

            {publishStatus !== 'first_publish_pending' && !_.isEmpty(downloadUrl) && (
                <Typography align="justify" variant="subtitle1" sx={{ mb: 3 }}>
                    Seu aplicativo Android já está publicado e disponível para download <Link target="_blank" href={downloadUrl} >neste link</Link>. Alterou algum dado que exige a publicação de uma atualização? Clique no botão “publicar” para enviar o seu aplicativo para o processo de aprovação da Play Store.
                </Typography>
            )}

            <Typography align="justify" variant="subtitle1" sx={{ mb: 3 }}>
                Caso você ainda não tenha criado a sua conta de desenvolvedor Google Play organizacional ou gostaria de rever algum tutorial, <Link className="cursor-pointer" onClick={props.goToFirstStep}>clique aqui</Link> para voltar ao início do processo de publicação do seu aplicativo Android.
            </Typography>

            <Box sx={{ px: { lg: 14 } }}>
                <Warnings deadlineInDays={20} downloadUrl={downloadUrl} platformName="Android" publishStatus={publishStatus} />

                {isAppBeingPublished('android') && (
                    <PublishingCard
                        lastPublishRequestedAt={appInfo.androidLastPublishRequestedAt}
                        text="Seu aplicativo Android está em aprovação e deve levar cerca de 10 dias úteis para ser liberado. Este prazo pode variar para mais ou para menos de acordo com a velocidade da revisão realizada pelo Google."
                    />
                )}

                {!isAppBeingPublished('android') && isMissingData() &&
                    <MissingDataCard />
                }

                {(publishStatus == 'first_publish_pending' || publishStatus == 'pending_update') && !isMissingData() &&
                    <PublishButton platform="android" text="Tenho uma conta de desenvolvedor Google Play do tipo organizacional ativa, concedi um acesso ao email suporte@mobilizei.com.br e estou ciente do prazo de publicação e dos demais avisos acima." />
                }

                {publishStatus == 'published' && !isMissingData() &&
                    <PublishedCard platform="Android" />
                }

                {publishStatus == 'developer_account_pending' &&
                    <WarningCard
                        content={<>
                            Você precisa criar a sua conta de desenvolvedor Google Play do tipo organizacional para seguir com a publicação do seu aplicativo Android!{' '}
                            <a href="https://mobilizei.com.br/tutoriais/como-criar-conta-desenvolvedor-google-play" target="_blank">Clique aqui</a>{' '}
                            e veja como criar esta conta.
                        </>}
                    />
                }

                {publishStatus == 'pending_google_play_developer_account_verification' &&
                    <WarningCard
                        content={<>
                            Você deve realizar a verificação da sua conta de desenvolvedor Google Play do tipo organizacional para que seja possível publicar o seu aplicativo. Acesse sua conta por meio <a href="https://play.google.com/console" target="_blank">deste link</a> e confira o aviso exibido na tela inicial.
                        </>}
                    />
                }
            </Box>
        </Box>
    )
}