import React from 'react';
import _ from 'lodash';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

export default function EcommercePlatformOption({ data, onClick, selected, shouldFade }) {
    return (
        <Grid
            item
            xs={10}
            sm={5}
        >
            <Box
                onClick={onClick}
                sx={{
                    alignItems: 'center',
                    background: 'white',
                    border: selected ? '2px solid #3cd568' : '2px solid #C6C6C6',
                    borderRadius: 4,
                    cursor: 'pointer',
                    display: 'flex',
                    height: 75,
                    overflow: 'hidden',
                    padding: '0!important',
                    position: 'relative',
                    justifyContent: 'center',
                    opacity: shouldFade ? 0.25 : 1,
                    '& img': {
                        width: '110%'
                    }
                }}
            >
                <img src={require(`../../../assets/img/platforms/${data.id}.png`)} alt="logo" />
            </Box>
        </Grid>
    );
}