import React from 'react';
import _ from 'lodash';
import { Box, Grid, Typography } from "@mui/material";
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import useAppInfo from '../../../hooks/appInfo.hook';
import QRCode from 'qrcode.react';
import PublishedCard from './PublishedCard';
import DashboardLayout from "../../../layouts/Dashboard";
import { baseDeeplink } from '../../../utils/constants';

export default function Preview() {
    const { appInfo, isAppPublished } = useAppInfo();
    const appLink = `${baseDeeplink}&deep_link_value=${appInfo.platformId}&deep_link_sub1=${appInfo.storeId}`;

    return (
        <DashboardLayout
            Icon={SmartphoneIcon}
            title="Veja seu aplicativo no celular"
            subtitle="Leia o QR Code e teste o seu aplicativo no seu celular"
        >
            <Grid
                container
                justifyContent={'center'}
            >
                <Grid item md={8} xs={12} paddingX={2} paddingY={4}>
                    {isAppPublished() && <PublishedCard />}

                    <Box display='flex' alignItems='left' flexDirection='column' marginY={4}>
                        <Typography align="justify" variant="subtitle1" marginTop={2}>
                            Você pode usar o{' '}
                            <a
                                target='_blank'
                                href="https://mobilizei.com.br/tutoriais/como-ver-aplicativo-sua-loja-celular"
                            >
                                aplicativo da Mobilizei
                            </a>
                            {' '}para testar os aplicativos da sua loja antes mesmo de publicá-los
                            na Google Play e na App Store!

                        </Typography>
                        <Typography align="justify" variant="subtitle1" marginTop={2}>
                            Acesse <a href={appLink} target='blank'>esse link</a> no seu 
                            celular ou então escaneie o QR Code abaixo para instalar o
                            aplicativo da Mobilizei.
                        </Typography>
                    </Box>

                    <Box display='flex' alignItems='center' justifyContent='start' flexDirection='column'>
                        <Typography align="justify" variant="h6" marginBottom={2}>
                            <strong>QR Code da sua loja</strong>
                        </Typography>

                        <QRCode value={appLink} size={250} />
                    </Box>

                    <Box display='flex' alignItems='center' justifyContent='center' marginY={2}>
                        <Typography align="center" variant="body1">
                            Leia com:
                        </Typography>
                        <Box display='flex' alignItems='center' justifyContent='center' marginLeft={2}>
                            <Box sx={{
                                borderRadius: 1,
                                height: 45,
                                overflow: 'hidden',
                                width: 45
                            }}>
                                <img
                                    alt="Previewer Read QR Code"
                                    src={require('../../../assets/img/previewer_icon_ios.png')}
                                    style={{ height: 'auto', width: '100%' }}
                                />
                            </Box>
                            <Typography align="center" variant="h6" marginLeft={2} marginRight={2}>
                                ou
                            </Typography>
                            <img src={require('../../../assets/img/previewer_camera.svg').default} height="45" alt="Previewer Camera" />
                        </Box>
                    </Box>

                    <Typography align="justify" variant="subtitle1" marginTop={2}>
                        Com o aplicativo Mobilizei instalado, leia o QR Code novamente para carregar o aplicativo da sua loja!
                    </Typography>
                </Grid>
            </Grid>
        </DashboardLayout >
    )
}