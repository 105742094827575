import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import Header from './Header';
import Smartphone from './Smartphone';
import { Box, Container, Hidden, Toolbar, useMediaQuery } from '@mui/material';
import { useOnboarding } from '../../../contexts/onboarding.context';
import { useUser } from '../../../contexts/user.context';
import { mobilizeiStoresService } from '../../../services/mobilizei/stores.service';
import { showApiError } from '../../../utils/errors';
import WelcomeDialog from './WelcomeDialog';
import DrawerMenu from './DrawerMenu';
import Tour from './Tour';
import { PLATFORMS } from '../../../utils/constants';

const useStyles = makeStyles((theme) => ({
    main: {
        backgroundColor: theme.palette.grey[100],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    tourWrapper: {
        bottom: 0,
        left: 0,
        position: 'absolute'
    }
}));

export default function Previewer() {
    const classes = useStyles();

    const muiTheme = useTheme();
    const isLargeScreen = useMediaQuery(muiTheme.breakpoints.up('md'));

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isTourOpen, setIsTourOpen] = useState(false);

    const { theme, setTheme } = useOnboarding();
    const { user } = useUser()

    useEffect(() => {
        if (!theme && user?.store?.platformId && user?.store?.id) {
            mobilizeiStoresService.getThemeById(user?.store?.platformId, user?.store?.id, 'webview')
                .then((data) => {
                    setTheme(data);
                })
                .catch(e => {
                    showApiError(e?.error);
                })
        }
    }, [])

    useEffect(() => {
        if (user.store?.platformId) {
            const isPlatformWithTour = [PLATFORMS.NUVEMSHOP, PLATFORMS.TRAY].includes(user.store?.platformId);

            if (isLargeScreen && isPlatformWithTour) {
                setIsDialogOpen(false);
                setIsTourOpen(true);
            } else {
                setIsDialogOpen(true);
                setIsTourOpen(false);
            }
        }
    }, [user])

    return (
        <>
            <Box sx={{ display: 'flex', position: 'relative' }}>
                <DrawerMenu />

                <Box className={classes.main} component="main" >
                    <Hidden mdDown>
                        <Header />
                        <Toolbar />
                    </Hidden>

                    <Container >
                        <Box
                            alignItems='center'
                            display='flex'
                            justifyContent='center'
                            paddingBottom={window.innerWidth > 600 ? 5 : 0}
                        >
                            <Smartphone />
                        </Box>
                    </Container>
                </Box>
            </Box>

            {isTourOpen &&
                <div className={classes.tourWrapper}>
                    <Tour />
                </div>
            }

            <WelcomeDialog isOpen={isDialogOpen} setIsOpen={setIsDialogOpen} />
        </>
    );
}
