import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Container } from '@mui/material';
import Logo from '../assets/img/logo.svg';
import DrawerMenu from './Dashboard/DrawerMenu';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    container: {
        background: `url(${require('../assets/img/brand-dots.svg').default}) no-repeat -350px 100px`,
        minHeight: '100vh',
        padding: '24px 0 0 0',
        overflowY: 'auto',
        "&:before": {
            background: `url(${require('../assets/img/brand-circle.svg').default}) no-repeat`,
            backgroudOverflow: 'hidden',
            backgroundSize: '100% 100%',
            content: '""',
            height: 500,
            position: "absolute",
            right: -300,
            top: -150,
            width: 500,
            zIndex: -999,
            '@media (max-width: 960px)': {
                right: -400,
                top: -200,
            }
        },
        '@media (max-width: 960px)': {
            background: `url(${require('../assets/img/brand-dots.svg').default}) no-repeat -450px 200px`,
        }
    },
    logo: {
        position: 'static',
        paddingLeft: 16,
        cursor: "pointer"
    }
}));

export default function BillingLayout({ fullScreen, children }) {
    const classes = useStyles();

    const navigate = useNavigate();

    return (
        <Box sx={{ display: 'flex', position: 'relative' }}>
            {!fullScreen && <DrawerMenu />}

            <Box component="main"
                sx={{
                    display: 'flex',
                    flexGrow: 1,
                    height: { md: '100vh', xs: fullScreen ? '100vh' : 'calc(100vh - 64px)' },
                    mt: { md: 0, xs: fullScreen ? '0' : '64px' },
                    overflow: 'auto',
                    zIndex: 10
                }}
            >
                <Container maxWidth={fullScreen ? false : 'lg'} className={classes.container}>
                    {fullScreen &&
                        <Box className={classes.logo}>
                            <img className={classes.logoImage} src={Logo} alt="logo" onClick={() => navigate('/')} />
                        </Box>
                    }

                    {children}
                </Container>
            </Box>
        </Box>
    );
}