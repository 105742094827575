import React from 'react';
import { Box, Card, CardContent, Typography } from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';

export default function WarningCard({ content, marginY }) {
    return (
        <Card elevation={1} sx={{ background: '#fff9c4', my: marginY || 8 }}>
            <CardContent sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <WarningIcon style={{ color: "#744210", fontSize: 35 }} />
                <Box marginLeft={4}>
                    <Typography style={{ color: "#744210" }} align="justify" variant="subtitle1" >
                        {content}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    )
}