import React, { useState } from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import config from '../../utils/config';
import image from '../../assets/img/integrationHelps/trayIntegrationHelp.png';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../contexts/user.context';

const useStyles = makeStyles(() => ({
    image: {
        width: '100%',
        border: '1px solid #e5e5e5',
        borderRadius: 10
    }
}));

export default function TrayIntegration() {
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { user } = useUser();

    const onRedirect = () => {
        setIsLoading(true);
        const url = `${user.store?.storeUrl}/auth.php?response_type=code&consumer_key=5a78cc179be64ad8aa397d6df3f2ac34321db0e7&callback=${config.TRAY_AUTH_CALLBACK}`
        window.open(url, "_self");
    }

    const onReturn = () => {
        navigate('/pricing')
    }

    const platformData = {
        name: "Tray",
        imgUrl: image,
        buttonText: "Autorizar"
    }

    return (
        <>
            <Typography component="h1" variant="h4" align='center' fontWeight='bold'>
                Plano Pro
            </Typography>

            <Grid
                container
                direction={'column'}
                justifyContent={'center'}
                gap={2}
                maxWidth={'md'}
                spacing={2}
                sx={{ mt: 6 }}
            >
                <Typography variant="subtitle1" align='center' lineHeight={1.5}>
                    O valor de <strong>R$ 199,00</strong> do Plano Pro será cobrado mensalmente junto com a fatura
                    da sua loja {platformData.name}.
                </Typography>

                <Typography variant="subtitle1" align='center' lineHeight={1.5}>
                    Ao clicar no botão <strong>Contratar</strong>, você será redirecionado para o painel administrativo da plataforma,
                    onde deverá clicar no botão <strong>{platformData.buttonText}</strong> para efetivar sua assinatura.
                </Typography>
            </Grid>

            <Grid
                container
                flexDirection={'column'}
                justifyContent={'center'}
                maxWidth={'md'}
                spacing={2}
                sx={{ mt: 4 }}
            >
                <Grid
                    justifyContent={'center'}
                    maxWidth='md'
                    spacing={2}
                >
                    <img
                        src={platformData.imgUrl}
                        alt='integração'
                        className={classes.image}
                    />
                </Grid>

                {isLoading ? (
                    <Grid
                        container
                        justifyContent='center'
                        sx={{ mt: 4, mb: 10 }}
                    >
                        <CircularProgress />
                    </Grid>
                ) : (
                    <Grid
                        container
                        direction={'row'}
                        justifyContent={'center'}
                        maxWidth={'md'}
                        spacing={2}
                    >
                        <Grid item xs={6} sm={2}>
                            <Button
                                disabled={false}
                                fullWidth
                                onClick={onReturn}
                                type="submit"
                                variant="text"
                                sx={{ mt: 4, mb: 10 }}
                            >
                                Voltar
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <Button
                                disabled={false}
                                fullWidth
                                onClick={onRedirect}
                                type="submit"
                                variant="contained"
                                sx={{ mt: 4, mb: 10 }}
                            >
                                Contratar
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </>
    );
}