import moment from 'moment';
import _ from 'lodash';
import { Chip } from '@mui/material';
import { subscriptionStatus } from './constants';

export const formatIsoTimestamp = (timestamp, format) => {
    if (_.isEmpty(timestamp) || _.isEmpty(format)) {
        return null;
    }

    return moment(new Date(timestamp)).format(format);
}

export const mapIntegerInCentsToCurrency = value => {
    if (!_.isInteger(value)) {
        return null;
    }

    return `R$ ${(value / 100).toFixed(2).replace('.', ',')}`
}

export const getPaymentMethodLabel = paymentMethod => {
    switch (paymentMethod) {
        case 'bank_slip':
            return 'Boleto';
        case 'credit_card':
            return 'Cartão de crédito';
        case 'pix':
            return 'Pix'
        default:
            break;
    }
}

export const getInvoiceStatusChip = status => {
    if (_.isEmpty(status))
        return '-';

    let backgroundColor = "";
    let color = "";
    let label = "";

    switch (status) {
        case 'pending':
            backgroundColor = "#f0cc22";
            color = "#111111";
            label = "PENDENTE";
            break;
        case 'paid':
            backgroundColor = "#75bd59";
            color = "#fff";
            label = "PAGO";
            break;
        case 'canceled':
            backgroundColor = "#3f5264";
            color = "#fff";
            label = "CANCELADO";
            break;
        case 'partially_paid':
            backgroundColor = "#b4c435";
            color = "#111111";
            label = "PARCIALMENTE PAGO";
            break;
        case 'refunded':
            backgroundColor = "#7a5647";
            color = "#fff";
            label = "REEMBOLSADO";
            break;
        case 'expired':
            backgroundColor = "#f32013";
            color = "#fff";
            label = "EXPIRADO";
            break;
        case 'authorized':
            backgroundColor = "#6e4193";
            color = "#fff";
            label = "AUTORIZADO";
            break;
        default:
            break;
    }

    return (
        <Chip label={label} size="small" style={{ backgroundColor, color, fontFamily: '"museo-sans", Arial,Verdana', fontWeight: 600, padding: '0 2px' }} />
    )
}

export const getSubscriptionStatusChip = status => {
    if (_.isEmpty(status))
        return '-';

    let backgroundColor = "";
    let color = "";
    let label = "";

    switch (status) {
        case subscriptionStatus.PENDING:
            backgroundColor = "#f0cc22";
            color = "#111111";
            label = "PENDENTE";
            break;
        case subscriptionStatus.ACTIVE:
            backgroundColor = "#75bd59";
            color = "#fff";
            label = "ATIVA";
            break;
        case subscriptionStatus.PENDING_SUSPENSION:
        case subscriptionStatus.SUSPENDED:
            backgroundColor = "#3f5264";
            color = "#fff";
            label = "CANCELADA";
            break;
        case subscriptionStatus.EXPIRED:
            backgroundColor = "#f32013";
            color = "#fff";
            label = "EXPIRADA";
            break;
        default:
            break;
    }

    return (
        <Chip label={label} size="small" style={{ backgroundColor, color, fontFamily: '"museo-sans", Arial,Verdana', fontWeight: 600, padding: '0 2px' }} />
    )
}