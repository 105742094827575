import config from "../../utils/config";
import { authWixService } from "./auth.service";

export const apiFetcher = (storeId, at, path, init, attempt = 1) => {

    let url = `${config.MOBILIZEI_API_BASE_URL}/platforms/wix/api_forward/${path}`;

    if (!init?.headers) init = { ...init, headers: {} }
    init.headers['Authorization'] = `Bearer ${at}`;

    init.headers['Content-Type'] = 'application/json';

    return fetch(url, init).then(response => {
        if (response.status === 204) {
            return;
        }
        return response.json().then(data => {
            if (!response.ok) {
                if ((response.status === 403 || response.status === 401 
                    || response.status === 428 ) && attempt <= 3) {
                    return authWixService.refreshAccessToken(storeId)
                        .then(accessToken => {
                            return apiFetcher(storeId, accessToken, path, init, attempt + 1);
                        })
                        .catch((error) => {
                            return Promise.reject(error);
                        });
                }
                const error = data || response.statusText;
                return Promise.reject(error);
            }

            return data;
        })
    })
        .catch(error => {
            if (attempt <= 3) {
                return apiFetcher(storeId, at, path, init, attempt + 1);
            }

            return Promise.reject(error);
        });
};