import React from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { MbTextField } from '../../../components/MbTextFields';
import { useOnboarding } from '../../../contexts/onboarding.context';
import isUrl from 'is-url';

export default function StoreAccountUrl() {
    const { handleSubmit, register, formState: { errors } } = useForm();
    const { data, setData, goToNextStep } = useOnboarding();

    const onSubmit = (form) => {
        let storeAccountUrl = form.storeAccountUrl;
        if (!storeAccountUrl.includes('http')) {
            storeAccountUrl = 'https://' + storeAccountUrl;
        }

        setData({ ...data, storeAccountUrl })

        goToNextStep();
    }

    return (
        <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Typography component="h1" variant="h4" align='center' fontWeight='bold'>
                Qual é a url da página de conta do cliente da sua loja?
            </Typography>
            <Grid container maxWidth={"sm"}>
                <Typography sx={{ mt: 2 }} component="h2" variant="body1" align='center'>
                    Esta é a página onde o cliente visualiza os seus dados cadastrais e confere o status dos seus pedidos.
                </Typography>
            </Grid>

            <Grid
                container
                component="form"
                spacing={2}
                maxWidth={"sm"}
                direction={'row'}
                justifyContent={'center'}
                sx={{ mt: 6 }}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid item xs={12} sm={9}>
                    <MbTextField
                        error={errors.storeAccountUrl?.message}
                        placeholder="Ex.: https://www.minhaloja.com.br/minha-conta"
                        register={register("storeAccountUrl", {
                            validate: (value) => {
                                if (!value.includes('http')) {
                                    value = 'https://' + value;
                                }

                                if(value.includes('@'))
                                    return 'Parece que você digitou um e-mail! Por favor, insira a url da página de conta do cliente da sua loja. Ex.: https://www.minhaloja.com.br/minha-conta'

                                return isUrl(value) || "Parece que tem algo de errado com esta url! Por favor, verifique se esta é mesmo a url da página de conta do cliente da sua loja."
                            }
                        })}
                    />
                </Grid>

                <Grid item xs={6} sm={4}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 6 }}
                    >
                        Próximo
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}