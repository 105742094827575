import * as React from 'react';
import { Button, Dialog, Grid, Typography } from '@mui/material';

export default function WelcomeDialog({ isOpen, setIsOpen }) {
    return (
        <Dialog open={isOpen}>
            <Grid container justifyContent='center' sx={{ padding: 3 }}>
                <Typography component="h1" variant="h5" align='center'>
                    Seu aplicativo está pronto!
                </Typography>
                <Typography sx={{ mt: 2 }} component="h2" variant="body1" align='justify'>
                    Navegue pelo app ou então escaneie o <strong>QR Code</strong> para vê-lo <strong>diretamente no seu celular.</strong>
                </Typography>
                <Typography sx={{ mt: 2 }} component="h2" variant="body1" align='justify'>
                    Depois é só clicar no botão "Publicar aplicativo" para escolher um plano e disponibilizar seu app para todos os seus clientes!
                </Typography>
                <Grid item xs={8} sm={4}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3 }}
                        onClick={() => setIsOpen(false)}
                    >
                        Ver aplicativo
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    );
}