import React from 'react';
import { makeStyles } from '@mui/styles';
import { useUser } from '../../../contexts/user.context';
import { availablePlans, planPeriodicity, plans, PLATFORMS } from '../../../utils/constants';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
    yearlyPrice: {
        fontSize: 16,
        margin: 0,
        fontFamily: 'Cabin, sans-serif',
    },
    note: {
        fontSize: 12,
        margin: 0,
        fontFamily: 'Cabin, sans-serif',
    },
    plan: {
        display: "flex",
        flexDirection: "column",
        height: "fit-content",
        background: "#fff",
        borderRadius: "8px",
        padding: "18px 28px",
        margin: "0 10px",
        boxShadow: "0px 0px 24px 0px #00000014",
        boxSizing: "border-box",
        minWidth: "400px",
        maxWidth: "400px"
    },
    planName: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "10px",

        "& h1": {
            fontSize: 36,
            lineHeight: 0,
            textAlign: "center",
            letterSpacing: "0.02em",
            fontFamily: 'Cabin, sans-serif',
        },
        "& h4": {
            fontSize: 16,
            fontWeight: 400,
            letterSpacing: "0.05em",
            textAlign: "center",
            fontFamily: 'Cabin, sans-serif',
            height: "2lh",
        }
    },
    planPrice: {
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
        justifyContent: "center",
        gap: "10px",
        padding: "2px 24px",
        marginTop: '15px',
        height: "2lh",

        "& h1": {
            fontSize: 36,
            fontWeight: 700,
            lineHeight: 0,
            letterSpacing: "0.02em",
            textAlign: "center",
            fontFamily: 'Cabin, sans-serif',
        },
        "& h2": {
            fontSize: 20,
            fontWeight: 400,
            lineHeight: 0,
            letterSpacing: "0.02em",
            textSlign: "center",
            fontFamily: 'Cabin, sans-serif',
        }
    },
    benefits: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        marginTop: "20px",
        justifyContent: "center",

        "& h4": {
            color: "#000",
            fontSize: 16,
            fontWeight: 700,
            margin: "10px 0",
            fontFamily: 'Cabin, sans-serif',
        }
    },
    benefit: {
        display: "flex",
        flexDirection: "row",
        columnGap: "10px",
        alignItems: "center",

        "& img": {
            height: 22,
            width: 22,
        },
        "& h4": {
            color: "#000",
            fontSize: 16,
            fontWeight: 400,
            lineHeight: '16px',
            margin: "10px 0",
            fontFamily: 'Cabin, sans-serif',
        }
    },
    startNowButton: {
        color: "#fff",
        fontWeight: 700,
        textAlign: "center",
        fontFamily: 'Cabin, sans-serif',

        backgroundColor: "#35c45f",
        padding: "8px 12px",
        margin: "10px 8px",
        cursor: "pointer",
        userSelect: "none",

        borderRadius: "8px",
        borderColor: "#35c45f",
        borderWidth: "2px",
        transition: "all .3s cubic-bezier(0.76, 0, 0.24, 1)",
        backfaceVisibility: "hidden",
        "&:hover": { transform: "translateY(-5px)" }
    },
    unavailable: {
        color: "#a2a2a2",
        fontWeight: 700,
        textAlign: "center",
        fontFamily: 'Cabin, sans-serif',

        padding: "8px 12px",
        margin: "10px 8px",
    },
}));

const basicTexts = {
    description: "Perfeito para iniciar no mundo dos aplicativos",
    checkedBenefits: [
        "Aplicativo Android e iOS",
        "Notificações push ilimitadas",
        "Integrado com a sua plataforma",
        "Sincronização em tempo real com a sua loja",
        "Atendimento via e-mail"
    ],
    uncheckedBenefits: [
        "Lista de desejos",
        "Diversos temas",
        "Notificações de carrinho abandonado",
        "Editor de telas",
        "Integração com WhatsApp"
    ]
}

const proTexts = {
    description: "Ideal para expandir sua presença mobile",
    checkedBenefits: [
        "Aplicativo Android e iOS",
        "Notificações push ilimitadas",
        "Integrado com a sua plataforma",
        "Sincronização em tempo real com a sua loja",
        "Atendimento via e-mail",
        "Lista de desejos",
        "Diversos temas",
        "Notificações de carrinho abandonado",
        "Editor de telas",
        "Integração com WhatsApp"
    ],
    uncheckedBenefits: []
}

export default function PlanCard({ periodicity, planType, onSelect, hideMissingFeatures }) {

    const { user } = useUser();
    const classes = useStyles();

    const isYearly = periodicity == planPeriodicity.YEARLY;

    const planTexts = planType == 'basic' ? basicTexts : proTexts

    const planData = {
        ...plans[availablePlans[planType][periodicity]],
        ...planTexts
    }

    return (
        <div className={classes.plan}>
            <div className={classes.planName}>
                <h1>{planData.name}</h1>
                <h4>{planData.description}</h4>
                <div className={classes.planPrice}>
                    <h2>R$</h2>
                    <h1>{planData.price},00 /mês</h1>
                </div>

                {isYearly && (<p className={classes.yearlyPrice}>R$ {planData.totalPrice},00 por ano</p>)}
                {isYearly && (<p className={classes.note}>em até <strong>6 vezes sem juros</strong></p>)}
                {!isYearly &&
                    <p className={classes.note}>
                        {user.store?.platformId === PLATFORMS.TRAY && !planData.isBasic
                            ? 'na fatura da sua loja Tray'
                            : 'no boleto, pix ou cartão de crédito'}
                    </p>
                }
            </div>
            <div className={classes.benefits}>
                {planData.checkedBenefits.map((item, index) => (
                    <div key={index} className={classes.benefit}>
                        <img src={require("../../../assets/img/plans/checked.svg").default} alt='icon' />
                        <h4>{item}</h4>
                    </div>
                ))}

                {!hideMissingFeatures && planData.uncheckedBenefits.map((item, index) => (
                    <div key={index} className={classes.benefit}>
                        <img src={require("../../../assets/img/plans/unchecked.svg").default} alt='icon' />
                        <h4>{item}</h4>
                    </div>
                ))}

                {user.store?.platformId == PLATFORMS.TRAY && !planData.isBasic && periodicity == planPeriodicity.YEARLY
                    ? <div className={classes.unavailable}>Não disponível</div>
                    : <div className={classes.startNowButton} onClick={() => onSelect(planType)}>
                        selecionar plano
                    </div>
                }
            </div>
        </div>

    )
}