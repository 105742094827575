import React, { useEffect } from 'react';
import _ from 'lodash';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useOnboarding } from '../../../contexts/onboarding.context';
import { CircularProgress } from '@mui/material';
import { showApiError } from '../../../utils/errors';
import { mobilizeiUsersService } from '../../../services/mobilizei/users.service';
import useAuthentication from '../../../hooks/authentication.hook';
import { APP_AUTH_TOKEN } from '../../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../contexts/user.context';

export default function CreatingApp() {
    const { goToNextStep, data, setTheme } = useOnboarding();
    const { setToken } = useAuthentication();
    const { user, setUser } = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        // Remove empty properties
        const dataToPost = _.omitBy(data, _.isEmpty);

        mobilizeiUsersService.completeOnboarding(dataToPost)
            .then(response => {
                if (response?.token && response?.theme) {
                    // We are setting the local storage token here to
                    // avoid a race condition that does not update
                    // the token on the authentication hook
                    localStorage.setItem(APP_AUTH_TOKEN, response.token)

                    setToken(response.token);
                    setTheme(response.theme);
                    setUser({ ...user, isOnboardingCompleted: true })
                }

                // Check if we should go to the panel or show the
                // app previewer
                if (data.ecommercePlatformAllowsIframe) {
                    goToNextStep();
                } else {
                    // swd: show welcome dialog
                    navigate('/?swd=1');
                }
            })
            .catch(e => {
                showApiError(e.error);
            })
    }, [])

    return (
        <Box
            sx={{
                marginTop: '20vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            <Typography component="h1" variant="h4" align='center'>
                O aplicativo da sua loja está sendo criado...
            </Typography>

            <Grid
                container
                justifyContent='center'
                sx={{ mt: 6 }}
            >
                <CircularProgress />
            </Grid>
        </Box>
    );
}