import React, { useEffect, useState } from 'react';
import { Box, Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import _ from 'lodash';
import SaveButton from '../../../components/SaveButton';
import useAppInfo from '../../../hooks/appInfo.hook';
import DashboardLayout from '../../../layouts/Dashboard';
import { mobilizeiStoresService } from '../../../services/mobilizei/stores.service';
import { toast } from 'react-toastify';
import WarningCard from '../../../components/publish/WarningCard';
import { useUser } from '../../../contexts/user.context';
import { useForm } from 'react-hook-form';
import { MbTextField } from '../../../components/MbTextFields';

export default function LastViewedProductNotification() {
    const { appInfo } = useAppInfo();
    const { isWebviewTheme, user } = useUser();

    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);

    const { getValues, handleSubmit, register, reset, watch, formState: { errors, isDirty } } = useForm({
        defaultValues: {
            isActive: false,
            title: '',
            text: '',
            delayInMinutes: '60'
        }
    });

    const onSubmit = () => {
        let data = getValues();

        setIsSaving(true);

        mobilizeiStoresService.setStoreFeatureById(
            appInfo.platformId,
            appInfo.storeId,
            'lastViewedProductNotification',
            data
        )
            .then(() => {
                toast.success('Alterações salvas com sucesso!');

                // Calling the reset function with an object will update the form defaultValues 
                // and subsequent calls to reset() will use the new default values
                reset(data);
            })
            .finally(() => {
                setIsSaving(false);
            })
    }

    useEffect(() => {
        mobilizeiStoresService.getStoreFeatureById(
            appInfo.platformId,
            appInfo.storeId,
            'lastViewedProductNotification'
        ).then(feature => {
            // Set form initial data
            reset({
                isActive: feature?.isActive,
                title: feature?.title,
                text: feature?.text,
                delayInMinutes: feature?.delayInMinutes
            })
        }).finally(() => {
            setIsLoading(false);
        })
    }, []);

    return (
        <DashboardLayout
            Icon={PreviewOutlinedIcon}
            title="Último produto visto"
            subtitle="Configure uma notificação para lembrar seu cliente do último produto que ele viu"
        >
            {isLoading ? (
                <Box display='flex' alignItems="center" justifyContent="center" height='100%'>
                    <CircularProgress />
                </Box>
            ) : (
                <Box flexDirection="column" flexGrow={1} display='flex' justifyContent='flex-start'>
                    {isWebviewTheme && user?.store?.id !== "0yocOuWnMM548MCw94mM" && (
                        <WarningCard
                            marginY={2}
                            content={<>
                                O envio da notificação de último produto visto não é compatível com o tema Web. Selecione outro tema para poder usar esta função.
                            </>}
                        />
                    )}
                    <Grid
                        container
                        component="form"
                        spacing={2}
                        direction={'row'}
                        justifyContent={'center'}
                        sx={{ mt: 2, mb: { xs: 18, md: 14 }, }}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={watch('isActive')}
                                            {...register('isActive')}
                                        />
                                    }
                                    label="Ativar notificação de último produto visto"
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <MbTextField
                                disabled={!watch('isActive')}
                                error={errors.title?.message}
                                helper={`Caracteres restantes: ${60 - watch('title', '').length}`}
                                maxLength="60"
                                label="Título (opcional)"
                                register={register("title")}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <MbTextField
                                disabled={!watch('isActive')}
                                error={errors.text?.message}
                                placeholder={'Ex.: Não deixe de dar mais uma olhada no {{produto}} dentro do nosso aplicativo!'}
                                helper={`Você pode incluir o nome do produto na notificação adicionando a expressão {{produto}} na sua mensagem. Caracteres restantes: ${400 - watch('text', '').length}`}
                                maxLength="400"
                                label="Mensagem *"
                                register={register("text", {
                                    required: "A mensagem é obrigatória."
                                })}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl variant='outlined' fullWidth>
                                <label>Enviar a notificação quanto tempo depois do último produto ser visto?</label>
                                <Select
                                    fullWidth
                                    disabled={!watch('isActive')}
                                    displayEmpty
                                    variant='outlined'
                                    {...register('delayInMinutes')}
                                    value={watch('delayInMinutes')}
                                    error={errors.delayInMinutes?.message}
                                >
                                    <MenuItem value={'1'}>1 minuto</MenuItem>
                                    <MenuItem value={'5'}>5 minutos</MenuItem>
                                    <MenuItem value={'15'}>15 minutos</MenuItem>
                                    <MenuItem value={'30'}>30 minutos</MenuItem>
                                    <MenuItem value={'60'}>1 hora</MenuItem>
                                    <MenuItem value={'120'}>2 horas</MenuItem>
                                    <MenuItem value={'240'}>4 horas</MenuItem>
                                    <MenuItem value={'360'}>6 horas</MenuItem>
                                    <MenuItem value={'720'}>12 horas</MenuItem>
                                    <MenuItem value={'1440'}>1 dia</MenuItem>
                                    <MenuItem value={'2880'}>2 dias</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <SaveButton
                            isLoading={isSaving}
                            onCancel={() => reset()}
                            show={isDirty}
                        />
                    </Grid>
                </Box>
            )}
        </DashboardLayout >
    )
}