import config from "../../utils/config";
import qs from "qs";
import { APP_AUTH_TOKEN, APP_UTMS } from "../../utils/constants";

export const apiFetcher = (path, options, isPostingJson = true, returnHeaders = false) => {
    const at = window.localStorage.getItem(APP_AUTH_TOKEN);

    let url = `${config.MOBILIZEI_API_BASE_URL}/${path}`;

    if (!options?.headers) options = { ...options, headers: {} }
    options.headers['Authorization'] = `Bearer ${at}`;

    if (isPostingJson)
        options.headers['Content-Type'] = 'application/json';
    
    // Set the utms on url
    let utms = window.localStorage.getItem(APP_UTMS);
    utms = qs.stringify(JSON.parse(utms));
    if(url.includes('?')) {
        url += `&${utms}`
    } else {
        url += `?${utms}`
    }

    // It we don't have query parameters, guarantee that we are not
    // sending a "?" in the end of the url request to avoid errors
    // on backend
    url = url.charAt(url.length - 1) === "?" ? url.slice(0, -1) : url;

    return fetch(url, options).then(response => {
        if (response.status === 204) {
            return;
        }

        // Handle 401 and 403 errors
        if (response.status === 401 || response.status === 403) {

            // Remove the at from the local storage and reload to
            // redirect to login
            window.localStorage.removeItem(APP_AUTH_TOKEN);
            window.location.reload();

            return Promise.reject();
        }

        // handle 404 errors
        if (response.status === 404) {
            return Promise.reject();
        }

        return response.json().then(data => {
            if (!response.ok) {
                const error = data || response.statusText;
                return Promise.reject(error);
            }
            if (returnHeaders) {
                return { data, headers: response.headers }
            } else {
                return data;
            }
        });
    });
};