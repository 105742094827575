import { apiFetcher } from "./apiFetcher";
import _ from 'lodash'

const categoriesPerPage = 100

export const categoriesService = {
  getCategories,
  getAllCategories
};

function getCategories(storeId, page, accessToken) {
  const url = `stores/v1/collections/query`

  const body = {
    query: {
      paging: {
        offset: (page-1) * categoriesPerPage
      }
    }
  }

  return apiFetcher(storeId, accessToken, url, { method: "POST", body: JSON.stringify(body) });
}

function getAllCategories(storeId, page = 1, categoriesArray = [], accessToken) {
  return getCategories(storeId, page, accessToken)
    .then(response => {
      categoriesArray = categoriesArray.concat(response?.collections);
      page += 1;
      if(!_.isEmpty(response?.collections)){
        return getAllCategories(storeId, page, categoriesArray, accessToken);
      }else{
        return categoriesArray
      }
    })
    .catch(() => {
      return categoriesArray;
    })
}