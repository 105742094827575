import React from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { MbTextField } from '../../../components/MbTextFields';
import { useOnboarding } from '../../../contexts/onboarding.context';

export default function EcommercePlatformName() {
    const { handleSubmit, register, formState: { errors } } = useForm();
    const { data, setData, goToNextStep } = useOnboarding();

    const onSubmit = (form) => {
        setData({
            ...data,
            ecommercePlatformName: form.ecommercePlatformName
        })

        goToNextStep();
    }

    return (
        <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Typography component="h1" variant="h4" align='center' fontWeight='bold'>
                Qual é o nome da sua plataforma de e-commerce?
            </Typography>

            <Grid
                container
                component="form"
                spacing={2}
                maxWidth={"sm"}
                direction={'row'}
                justifyContent={'center'}
                sx={{ mt: 6 }}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid item xs={12} sm={9}>
                    <MbTextField
                        error={errors.ecommercePlatformName?.message}
                        placeholder="Insira o nome da sua plataforma de e-commerce"
                        register={register("ecommercePlatformName", {
                            required: " "
                        })}
                    />
                </Grid>

                <Grid item xs={6} sm={4}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 6 }}
                    >
                        Próximo
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}