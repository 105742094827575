const getAll = () => {
    return ecommercePlatforms;
}

const getById = (id) => {
    return ecommercePlatforms.find(ep => ep.id === id);
}

export default {
    getAll,
    getById
}

const ecommercePlatforms = [
    {
        "id": "bagy",
        "name": "Bagy",
        "storeAccountUrl": "/conta",
        "storeCartUrl": "/carrinho",
        "isIframeAllowed": true
    },
    {
        "id": "corecommerce",
        "name": "CoreCommerce",
        "storeAccountUrl": "/login.php?m=client_login",
        "storeCartUrl": "/cart.php?m=view",
        "isIframeAllowed": false
    },
    {
        "id": "dlojavirtual",
        "name": "DLoja Virtual",
        "storeAccountUrl": "/clientes/index",
        "storeCartUrl": "/carrinho",
        "isIframeAllowed": false
    },
    {
        "id": "dooca",
        "name": "Dooca Commerce",
        "storeAccountUrl": "/conta",
        "storeCartUrl": "/carrinho",
        "isIframeAllowed": true
    },
    {
        "id": "fastcommerce",
        "name": "Fastcommerce",
        "storeAccountUrl": "/account.asp",
        "storeCartUrl": "/checkout.asp",
        "isIframeAllowed": false
    },
    {
        "id": "iluria",
        "name": "Iluria",
        "storeAccountUrl": "/login-ssl.html",
        "storeCartUrl": "/cart-content.html",
        "isIframeAllowed": false
    },
    {
        "id": "irroba",
        "name": "Irroba",
        "storeAccountUrl": "/login",
        "storeCartUrl": "/carrinho",
        "isIframeAllowed": false
    },
    {
        "id": "iset",
        "name": "iSET",
        "storeAccountUrl": "/login",
        "storeCartUrl": "/cart",
        "isIframeAllowed": false
    },
    {
        "id": "jet",
        "name": "JET",
        "storeAccountUrl": "/customer/login",
        "storeCartUrl": "/checkout",
        "isIframeAllowed": false
    },
    {
        "id": "linx",
        "name": "Linx Commerce",
        "storeAccountUrl": "/login",
        "storeCartUrl": "/carrinho",
        "isIframeAllowed": false
    },
    {
        "id": "lojaintegrada",
        "name": "Loja Integrada",
        "storeAccountUrl": "/conta/login",
        "storeCartUrl": "/carrinho/index",
        "isIframeAllowed": true
    },
    {
        "id": "lojamestre",
        "name": "Loja Mestre",
        "storeAccountUrl": "/identificado.asp",
        "storeCartUrl": "/cesta.asp?atualizasim=atualizarcompras&calc=1",
        "isIframeAllowed": true
    },
    {
        "id": "magento",
        "name": "Magento",
        "storeAccountUrl": "/customer/account",
        "storeCartUrl": "/checkout/cart",
        "isIframeAllowed": false
    },
    {
        "id": "mercadoshops",
        "name": "Mercado Shops",
        "storeAccountUrl": "",
        "storeCartUrl": "/gz/cart",
        "isIframeAllowed": false
    },
    {
        "id": "mkx",
        "name": "MKX",
        "storeAccountUrl": "/login",
        "storeCartUrl": "/carrinho-de-compra",
        "isIframeAllowed": true
    },
    {
        "id": "nuvemshop",
        "name": "Nuvemshop",
        "storeAccountUrl": "/account/login",
        "storeCartUrl": "/comprar",
        "isIframeAllowed": true
    },
    {
        "id": "opencart",
        "name": "OpenCart",
        "storeAccountUrl": "/index.php?route=account/login",
        "storeCartUrl": "/index.php?route=checkout/cart",
        "isIframeAllowed": true
    },
    {
        "id": "prestashop",
        "name": "PrestaShop",
        "storeAccountUrl": "/minha-conta",
        "storeCartUrl": "/pedido",
        "isIframeAllowed": true
    },
    {
        "id": "shopify",
        "name": "Shopify",
        "storeAccountUrl": "/account",
        "storeCartUrl": "/cart",
        "isIframeAllowed": false
    },
    {
        "id": "tray",
        "name": "Tray",
        "storeAccountUrl": "/central-do-cliente",
        "storeCartUrl": "/loja/redirect_cart_service.php",
        "isIframeAllowed": true
    },
    {
        "id": "vnda",
        "name": "Vnda",
        "storeAccountUrl": "/conta",
        "storeCartUrl": "/carrinho",
        "isIframeAllowed": false
    },
    {
        "id": "vtex",
        "name": "VTEX",
        "storeAccountUrl": "/account",
        "storeCartUrl": "/checkout",
        "isIframeAllowed": false
    },
    {
        "id": "wbuy",
        "name": "WBUY",
        "storeAccountUrl": "/login",
        "storeCartUrl": "/carrinho",
        "isIframeAllowed": false
    },
    {
        "id": "wapstore",
        "name": "wap.store",
        "storeAccountUrl": "/checkout/acesso",
        "storeCartUrl": "/checkout",
        "isIframeAllowed": false
    },
    {
        "id": "webstore",
        "name": "webstore",
        "storeAccountUrl": "/menu-cliente",
        "storeCartUrl": "/carrinho",
        "isIframeAllowed": true
    },
    {
        "id": "wix",
        "name": "Wix",
        "storeAccountUrl": "/my-account",
        "storeCartUrl": "/cart",
        "isIframeAllowed": false
    },
    {
        "id": "woocommerce",
        "name": "WooCommerce",
        "storeAccountUrl": "/my-account",
        "storeCartUrl": "/cart",
        "isIframeAllowed": false
    },
    {
        "id": "other",
        "name": "Outra",
        "isIframeAllowed": false
    }
]