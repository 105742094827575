import React, { useState } from 'react';
import _ from 'lodash';
import AppleIcon from '@mui/icons-material/Apple';
import useAppInfo from '../../../hooks/appInfo.hook';
import DashboardLayout from "../../../layouts/Dashboard";
import Step1 from './Step1';
import Step2 from './Step2';

export default function PublishIOS() {
    const { appInfo } = useAppInfo();

    const initialStep = appInfo.iOSPublishStatus === 'first_publish_pending' ? 1 : 2;
    const [currentStep, setCurrentStep] = useState(initialStep);

    return (
        <DashboardLayout
            Icon={AppleIcon}
            title="Aplicativo iOS"
            subtitle="Envie a versão iOS do seu aplicativo para a App Store"
        >
            {currentStep === 1 && <Step1 nextStep={() => setCurrentStep(2)} />}
            {currentStep === 2 && <Step2 goToFirstStep={() => setCurrentStep(1)} />}
        </DashboardLayout >
    )
}