import * as React from 'react'
import { onboardingSteps, platformsOnboardingIntegration } from '../utils/constants';

const OnboardingContext = React.createContext()

const initialData = {
    ecommercePlatformId: '',
    ecommercePlatformName: '',
    ecommercePlatformAllowsIframe: false,
    storeId: '',
    storeAccountUrl: '',
    storeCartUrl: '',
    storeName: '',
    storeUrl: ''
}

function useOnboarding() {
    const context = React.useContext(OnboardingContext);
    if (!context) {
        throw new Error(`useOnboarding must be used within a OnboardingContext`);
    }
    return context;
}

function OnboardingProvider({ children }) {
    const onboardingStepsOrder = [
        onboardingSteps.START,
        onboardingSteps.STORE_NAME,
        onboardingSteps.ECOMMERCE_PLATFORM,
        onboardingSteps.ECOMMERCE_PLATFORM_INTEGRATION,
        onboardingSteps.STORE_URL,
        onboardingSteps.ECOMMERCE_PLATFORM_NAME,
        onboardingSteps.STORE_CART_URL,
        onboardingSteps.STORE_ACCOUNT_URL,
        onboardingSteps.CREATING_APP,
        onboardingSteps.PREVIEWER
    ]

    const [currentStep, setCurrentStep] = React.useState(onboardingSteps.START)

    const [data, setData] = React.useState(initialData);

    const [theme, setTheme] = React.useState(null)

    const goToStep = (step) => {
        return setCurrentStep(step);
    }

    const goToNextStep = () => {
        if (currentStep === onboardingSteps.STORE_URL && data.ecommercePlatformId !== 'other') {
            setCurrentStep(onboardingSteps.CREATING_APP);
            return;
        }

        if(currentStep === onboardingSteps.ECOMMERCE_PLATFORM && 
            platformsOnboardingIntegration.includes(data.ecommercePlatformId))
        {
            return goToStep(onboardingSteps.ECOMMERCE_PLATFORM_INTEGRATION)
        }

        const currentStepIndex = onboardingStepsOrder.findIndex(e => e === currentStep);
        const nextStep = onboardingStepsOrder[currentStepIndex + 1];
        setCurrentStep(nextStep);
    }

    const goToPreviousStep = () => {
        const currentStepIndex = onboardingStepsOrder.findIndex(e => e === currentStep);
        const previousStep = onboardingStepsOrder[currentStepIndex - 1];
        setCurrentStep(previousStep);
    }

    const clearOnboardingContext = () => {
        setCurrentStep(onboardingSteps.START)
        setData(initialData)
        setTheme(null)
    }

    const values = {
        currentStep,
        goToStep,
        goToNextStep,
        goToPreviousStep,
        data,
        setData,
        theme,
        setTheme,
        clearOnboardingContext
    }

    return (
        <OnboardingContext.Provider value={values}>
            {children}
        </OnboardingContext.Provider>
    )
}

export { OnboardingContext, OnboardingProvider, useOnboarding }