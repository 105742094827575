import React from 'react';
import { Box, Card, CardContent, Typography } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function PublishedCard(props) {
    return (
        <Card elevation={1} sx={{ background: '#a5d6a7', my: 8 }}>
            <CardContent sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <CheckCircleIcon style={{ color: "#22543d", fontSize: 60 }} />
                <Box marginLeft={4}>
                    <Typography color='#1b5e20' align="justify" variant="subtitle1" >
                        {`A versão mais atualizada do seu aplicativo ${props.platform} já está disponível para download! No momento não é necessário solicitar uma nova publicação.`}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    )
}