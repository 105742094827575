import React from 'react';
import { Card, CardContent, Typography } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useAppInfo from '../../../hooks/appInfo.hook';

export default function PublishedCard() {
    const { appInfo, isAndroidAppPublished } = useAppInfo();

    const publishedPlatform = isAndroidAppPublished() ? 'Android' : 'iOS';
    const platformToTest = isAndroidAppPublished() ? 'iOS' : 'Android';
    const downloadUrl = isAndroidAppPublished() ? appInfo.androidDownloadUrl : appInfo.iOSDownloadUrl;

    return (
        <Card
            elevation={1}
            sx={{
                background: '#a5d6a7',
                mb: 6,
                p: 1
            }}
        >
            <CardContent sx={{ alignItems: 'center', display: 'flex' }} >
                <CheckCircleIcon sx={{ color: "#22543d", fontSize: 40, mr: 2 }} />
                <Typography sx={{ color: "#1b5e20" }} align="justify" variant="subtitle1">
                    A versão {publishedPlatform} do seu aplicativo está disponível para download{' '}
                    <a href={downloadUrl} target="_blank" style={{ color: '#247e3e' }}>neste link</a>.
                    Você pode seguir os passos abaixo para testar a versão {platformToTest} do seu aplicativo.
                </Typography>
            </CardContent>
        </Card>
    )
}