import { apiFetcher } from "./apiFetcher";

export const mobilizeiUsersService = {
  signIn,
  create,
  getSelf,
  requestPasswordReset,
  resetPassword,
  checkStoreUrl,
  completeOnboarding,
  updateUserData,
};

function signIn(email, password) {
  return apiFetcher(
    'users/signin', {
    method: "POST",
    body: JSON.stringify({ email: email.trim(), password })
  }).then(response => response);
}

function create(data) {
  return apiFetcher(
    'users', {
    method: "POST",
    body: JSON.stringify(data)
  }).then(response => response);
}

function getSelf() {
  return apiFetcher(
    'users/self', {
    method: "GET",
  }).then(response => response);
}

function requestPasswordReset(email) {
  return apiFetcher(
    'users/request_password_reset', {
    method: "POST",
    body: JSON.stringify({ email: email.trim() })
  }).then(response => response);
}

function resetPassword(password, token, userId) {
  return apiFetcher(
    'users/reset_password', {
    method: "POST",
    body: JSON.stringify({ password, token, userId })
  }).then(response => response);
}

function checkStoreUrl(ecommercePlatformId, storeUrl) {
  return apiFetcher(
    'users/self/onboarding/check_store_url', {
    method: "POST",
    body: JSON.stringify({ ecommercePlatformId, storeUrl })
  }).then(response => response);
}

function completeOnboarding(data) {
  return apiFetcher(
    'users/self/onboarding/complete', {
    method: "POST",
    body: JSON.stringify(data)
  }).then(response => response);
}

function updateUserData(data) {
  return apiFetcher(
    'users/self', {
    method: "PUT",
    body: JSON.stringify(data)
  }).then(response => response);
}