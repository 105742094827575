import * as React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    statusBarWrapper: {
        backgroundColor: '#fff',
        marginBottom: -7
    },
    statusBar: {
        width: '100%'
    },
}));

export default function StatusBar() {
    const classes = useStyles();

    return (
        <div className={classes.statusBarWrapper}>
            <img src={require('../../../../assets/img/status_bar.png')} alt="status_bar" className={classes.statusBar} />
        </div>

    );
}