import React from 'react';
import ReactDOM from 'react-dom/client';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './theme';
import { UserProvider } from './contexts/user.context';
import { OnboardingProvider } from './contexts/onboarding.context';
import { PlanProvider } from './contexts/plan.context';
import AppAuthentication from './auth/AppAuthentication';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import Hotjar from '@hotjar/browser';
import mixpanel from 'mixpanel-browser';
import { saveUtmsToLocalStorage } from './utils/utms';
import config from './utils/config';

// Add all Font Awesome icons
library.add(fas, far, fab)

// Init Hotjar if we are on prod environment
if (process.env.REACT_APP_ENV === 'prod') {
  const siteId = 3806089;
  const hotjarVersion = 6;
  Hotjar.init(siteId, hotjarVersion);
}

// Init Mixpanel
mixpanel.init(config.MIXPANEL_PROJECT_TOKEN, { 
  api_host: "https://e.mobilizei.com.br",
  cross_subdomain_cookie: true,
  debug: process.env.REACT_APP_ENV !== 'prod', 
  track_pageview: 'url-with-path'
})

// Get the utms from url and save it to local storage
saveUtmsToLocalStorage();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        style={{ fontSize: 16, width: 600 }}
      />
      <CssBaseline />
      <UserProvider>
        <OnboardingProvider>
          <PlanProvider>
            <AppAuthentication />
          </PlanProvider>
        </OnboardingProvider>
      </UserProvider>
    </ThemeProvider>
  </React.StrictMode>
);