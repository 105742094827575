import React, { useState } from 'react';
import _ from 'lodash';
import { Box, Button, Card, CardActions, CardContent, CircularProgress, IconButton, Typography } from '@mui/material';
import AddCardIcon from '@mui/icons-material/AddCard';
import CreditCardFormDialog from './CreditCardFormDialog';
import RemoveCreditCardDialog from './RemoveCreditCardDialog';
import { mobilizeiSubscriptionsService } from '../../../../services/mobilizei/subscriptions.service';
import { useUser } from '../../../../contexts/user.context';
import { toast } from 'react-toastify';
import { showApiError } from '../../../../utils/errors';

const validCardBrands = ['amex', 'diners', 'elo', 'master', 'visa']

export default function PaymentMethod() {
    const { user, setUser } = useUser();
    const [isCreditCardFormDialogOpen, setIsCreditCardFormDialogOpen] = useState(false)
    const [isRemoveCreditCardDialogOpen, setIsRemoveCreditCardDialogOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    const hasPaymentMethod = !_.isEmpty(user?.subscription?.creditCardData) ? true : false;

    const onRemoveCreditCard = () => {
        if (!user?.subscription?.id) {
            return
        }

        setIsLoading(true);
        setIsRemoveCreditCardDialogOpen(false);

        mobilizeiSubscriptionsService.removePaymentMethod(user?.subscription?.id).then(() => {
            toast.success('Cartão de crédito removido com sucesso!');

            // Update the local user data to match the data that was 
            // updated on the backend
            setUser({
                ...user, subscription: {
                    ...user?.subscription,
                    creditCardData: {}
                }
            });
        })
            .catch(e => {
                showApiError(e.error);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <Box display='flex' flexDirection='column' width='100%'>
            <Typography variant="h5" mb={2}>
                Método de pagamento
            </Typography>

            {isLoading ? (
                <Card
                    elevation={2}
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        justifyContent: 'center'
                    }}
                >
                    <CircularProgress />
                </Card>
            ) : (
                hasPaymentMethod ? (
                    <>
                        <Card
                            elevation={2}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                flexGrow: 1,
                                justifyContent: 'space-between',
                                padding: 1
                            }}
                        >
                            <CardContent sx={{
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: { xs: 'column', sm: 'row' },
                                flexGrow: 1,
                                justifyContent: 'center'
                            }}>
                                <Box sx={{ 
                                    mb: { xs: 2, sm: 0 }, 
                                    mr: { xs: 0, sm: 6 },
                                    width: { xs: 100, sm: 130 },
                                }}>
                                    {user?.subscription?.creditCardData?.brand && validCardBrands.includes(_.toLower(user?.subscription?.creditCardData?.brand))
                                        ? <img
                                            src={require(`../../../../assets/img/billing/${_.toLower(user?.subscription?.creditCardData?.brand)}.svg`)}
                                            style={{ width: '100%' }}
                                        />
                                        : <img
                                            src={require('../../../../assets/img/billing/creditcard.svg').default}
                                            style={{ width: '100%' }}
                                        />
                                    }

                                </Box>

                                <Box>
                                    <Typography>
                                        {user?.subscription?.creditCardData?.displayNumber}
                                    </Typography>
                                    <Typography>
                                        {user?.subscription?.creditCardData?.holderName}
                                    </Typography>
                                    <Typography>
                                        {user?.subscription?.creditCardData?.month}/{user?.subscription?.creditCardData?.year}
                                    </Typography>
                                </Box>

                            </CardContent>
                            <CardActions disableSpacing sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                padding: 0,
                                justifySelf: 'flex-end',
                                alignSelf: 'flex-end'
                            }}>
                                <Button
                                    onClick={() => setIsRemoveCreditCardDialogOpen(true)}
                                    size="small"
                                    sx={{
                                        color: 'red',
                                        ':hover': { bgcolor: 'transparent' }
                                    }}>
                                    Remover
                                </Button>
                            </CardActions>
                        </Card>
                        <RemoveCreditCardDialog
                            isOpen={isRemoveCreditCardDialogOpen}
                            setIsOpen={setIsRemoveCreditCardDialogOpen}
                            onConfirm={() => onRemoveCreditCard()}
                        />
                    </>
                ) : (
                    <>
                        <Card
                            elevation={2}
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                flexGrow: 1,
                                justifyContent: 'center',
                                padding: 2
                            }}
                        >
                            <AddCardIcon />
                            <Typography variant="body1" align="center" color='textSecondary' my={2}>
                                Cadastre um cartão de crédito e automatize o pagamento da sua assinatura.
                            </Typography>
                            <Button
                                size='medium'
                                variant='contained'
                                onClick={() => setIsCreditCardFormDialogOpen(true)}
                            >
                                Adicionar cartão
                            </Button>
                        </Card>
                        <CreditCardFormDialog
                            isOpen={isCreditCardFormDialogOpen}
                            setIsOpen={setIsCreditCardFormDialogOpen}
                        />
                    </>
                )
            )}
        </Box>
    );
}