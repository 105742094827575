import React from 'react';
import _ from 'lodash';
import { Box, Grid, Typography } from '@mui/material';
import { MbTextField } from '../../../../components/MbTextFields';

export default function ChangePassword({ watch, errors, register }) {
    return (
        <Box mb={{ xs: 18, md: 14 }}>
            <Typography variant="h5">
                Alterar senha
            </Typography>
            <Typography align="left" variant="subtitle2" sx={{ marginBottom: 2 }} color="textSecondary">
                Caso você deseje trocar sua senha, preencha os campos abaixo com a sua senha atual e com uma nova senha
            </Typography>

            <Grid
                container
                component="form"
                spacing={2}
                direction={'row'}
            >
                <Grid item xs={12} sm={12}>
                    <MbTextField
                        error={errors.currentPassword?.message}
                        label="Senha atual"
                        register={register("currentPassword", {
                            required: !_.isEmpty(watch('currentPassword')) || !_.isEmpty(watch('password'))
                                || !_.isEmpty(watch('passwordConfirmation')) ? "A senha atual é obrigatória." : false
                        })}
                        type="password"
                    />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <MbTextField
                        error={errors.password?.message}
                        label="Nova senha"
                        register={register("password", {
                            required: !_.isEmpty(watch('currentPassword')) || !_.isEmpty(watch('password'))
                                || !_.isEmpty(watch('passwordConfirmation')) ? "A nova senha é obrigatória." : false
                        })}
                        type="password"
                    />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <MbTextField
                        error={errors.passwordConfirmation?.message}
                        label="Confirme sua nova senha"
                        register={register("passwordConfirmation", {
                            required: !_.isEmpty(watch('currentPassword')) || !_.isEmpty(watch('password'))
                                || !_.isEmpty(watch('passwordConfirmation')) ? "A confirmação da nova senha é obrigatória." : false,
                            validate: value => watch('password') === value || 'A confirmação de senha deve coincidir com a nova senha.'
                        })}
                        type="password"
                    />
                </Grid>
            </Grid>
        </Box>
    );
}