import { CircularProgress, Grid } from '@mui/material'

export default function AppLoading() {
    return (
        <Grid
            container
            justifyContent='center'
            alignItems='center'
            sx={{ height: '100vh', width: '100%' }}
        >
            <Grid item xs='auto'>
                <CircularProgress />
            </Grid>
        </Grid>
    )
}
