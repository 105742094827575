const devConfig = {
  MIXPANEL_PROJECT_TOKEN: "c857549796cc95d5c3efd1149f84df60",
  MOBILIZEI_API_BASE_URL: "https://api-dev.mobilizei.com.br/v1",
  MOBILIZEI_LAYOUT_EDITOR_URL: "https://layout-dev.mobilizei.com.br",
  NUVEMSHOP_AUTH_URL: "https://www.nuvemshop.com.br/apps/2107/authorize",
  TRAY_AUTH_CALLBACK: "https://api-dev.mobilizei.com.br/v1/platforms/tray/auth/callback",
  WIX_AUTH_URL: "https://pt.wix.com/installer/install?appId=5848639a-cbe9-4f12-ac3c-e13f5f2192fe",
  WIX_CHECKOUT_URL: "https://manage.wix.com/app-pricing-plans/5848639a-cbe9-4f12-ac3c-e13f5f2192fe/plan",
  WIX_DASHBOARD_URL: "https://manage.wix.com/dashboard",
  IUGU_ACCOUNT_ID: "CFFC16C7AB62EE984964E11ADFA8E022",
  IUGU_TEST_MODE: true
}

const prodConfig = {
  MIXPANEL_PROJECT_TOKEN: "f16616272520a3225e9614397dd0457e",
  MOBILIZEI_API_BASE_URL: "https://api.mobilizei.com.br/v1",
  MOBILIZEI_LAYOUT_EDITOR_URL: "https://layout.mobilizei.com.br",
  NUVEMSHOP_AUTH_URL: "https://www.nuvemshop.com.br/apps/1846/authorize",
  TRAY_AUTH_CALLBACK: "https://api.mobilizei.com.br/v1/platforms/tray/auth/callback",
  WIX_AUTH_URL: "https://pt.wix.com/installer/install?appId=ba361548-eef7-4859-9a35-8aaaeea93a77",
  WIX_CHECKOUT_URL: "https://manage.wix.com/app-pricing-plans/ba361548-eef7-4859-9a35-8aaaeea93a77/plan",
  WIX_DASHBOARD_URL: "https://manage.wix.com/dashboard",
  IUGU_ACCOUNT_ID: "CFFC16C7AB62EE984964E11ADFA8E022",
  IUGU_TEST_MODE: false
}

const config = process.env.REACT_APP_ENV === 'prod' ? prodConfig : devConfig;

export default config;