import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Logo from '../../../assets/img/logo.svg'

import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import QrCodeIcon from '@mui/icons-material/QrCode2Outlined';
import RocketIcon from '@mui/icons-material/RocketLaunchOutlined';
import BrushIcon from '@mui/icons-material/BrushOutlined';
import useAuthentication from '../../../hooks/authentication.hook';
import { Dialog } from '@mui/material';
import QrCode from './QrCode';
import TabBarColors from './TabBarColors';

const drawerWidth = 280;

export default function DrawerMenu() {
    const navigate = useNavigate();
    const { token } = useAuthentication()

    const [mobileOpen, setMobileOpen] = useState(false);

    const [showQrCode, setShowQrCode] = useState(false);
    const [showTabBarColors, setShowTabBarColors] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    let drawerItens = [
        {
            action: () => navigate('/'),
            title: 'Ir ao painel de controle',
            Icon: HomeIcon
        },
        {
            action: () => { 
                setMobileOpen(false);
                setShowQrCode(true);
            },
            title: 'Ver aplicativo no celular',
            Icon: QrCodeIcon
        },
        {
            action: () => { 
                setMobileOpen(false);
                setShowTabBarColors(true);
            },
            title: 'Editar menu inferior',
            Icon: BrushIcon
        },
        {
            action: () => navigate('/pricing'),
            title: 'Publicar aplicativo',
            Icon: RocketIcon
        },
    ]

    const listItemButtonStyle = {
        color: '#000',
        px: 3,
        py: 0.4,
    }

    const listItemIconStyle = di => ({
        color: '#000',
        minWidth: '35px',
        fontSize: 20
    })

    const drawer = (
        <Box display='flex' flexDirection='column' height='100%' minHeight={700} position='relative'>
            <Box display='flex' alignItems='center' justifyContent='center' height={64}>
                <img src={Logo} alt="logo" height={35} />
            </Box>

            <List>
                {drawerItens.map((di, index) => (
                    <ListItem disablePadding key={`item-${index}`}>
                        <ListItemButton
                            onClick={() => di.action()}
                            sx={listItemButtonStyle}
                        >
                            <ListItemIcon sx={listItemIconStyle(di)}>
                                <di.Icon sx={{ fontSize: 18 }} />
                            </ListItemIcon>
                            <ListItemText
                                primary={di.title}
                                primaryTypographyProps={{ fontSize: 15 }}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <>
            <Dialog open={showQrCode} onClose={() => setShowQrCode(false)}>
                <QrCode />
            </Dialog>

            <Dialog 
                open={showTabBarColors} 
                onClose={() => setShowTabBarColors(false)} 
                PaperProps={{ sx: { overflowY: 'visible!important' } }}
            >
                <TabBarColors />
            </Dialog>

            <Box sx={{ display: 'flex' }}>
                <AppBar
                    color='white'
                    position="fixed"
                    sx={{
                        display: { md: 'none' },
                        ml: { md: `${drawerWidth}px` },
                        width: { md: `calc(100% - ${drawerWidth}px)` }
                    }}
                >
                    <Toolbar sx={{ padding: '0!important' }}>
                        <IconButton
                            color="inherit"
                            onClick={handleDrawerToggle}
                            sx={{ display: { md: 'none' }, mx: 1.5 }}
                        >
                            <MenuIcon />
                        </IconButton>

                        <Box sx={{
                            backgroundColor: 'rgba(0, 0, 0, 0.12)',
                            height: '64px',
                            width: '1px',
                        }} />

                        <Box display='flex' sx={{ ml: 2 }}>
                            <img src={Logo} alt="logo" height={35} />
                        </Box>
                    </Toolbar>
                </AppBar>

                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { sm: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>

        </>
    );
}