import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import _ from 'lodash';

export default function CustomerData({ invoice }) {
    const billingInformation = invoice.billingInformation;
    const { city, complement, cpfCnpj, name, number, state, street } = billingInformation;

    return (
        <Box mb={4}>
            <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item xs={7}>
                    <Typography variant="subtitle2" align="left">
                        Nome
                    </Typography>
                    <Typography variant="subtitle1" align="left">
                        {name}
                    </Typography>
                </Grid>

                <Grid item xs={5}>
                    <Typography variant="subtitle2" align="right">
                        CPF/CNPJ
                    </Typography>
                    <Typography variant="subtitle1" align="right">
                        {cpfCnpj}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="subtitle2" align="left">
                        Endereço
                    </Typography>
                    <Typography variant="subtitle1" align="left">
                        {street}, {number}{_.isEmpty(complement) ? '' : `, ${complement}` }, {city}/{state}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}