import React, { useState } from 'react';
import { Box, Button, Checkbox, Grid, Typography } from "@mui/material";
import _ from "lodash";
import { MbTextField } from '../../../components/MbTextFields';
import { useUser } from "../../../contexts/user.context";
import { useForm } from 'react-hook-form';
import { mobilizeiStoresService } from '../../../services/mobilizei/stores.service';
import CompanyAccountDialog from './CompanyAccountDialog';

export default function Step1({ nextStep }) {
    const { user, setUser } = useUser();

    const [isAccountAgreementChecked, setIsAccountAgreementChecked] = useState(false);
    const [isPartnerAgreementChecked, setIsPartnerAgreementChecked] = useState(false);
    const [isContentAgreenmentChecked, setIsContentAgreenmentChecked] = useState(false);
    const [isTermAgreementChecked, setIsTermAgreementChecked] = useState(false);

    const [showCompanyAccountDialog, setShowCompanyAccountDialog] = useState(false);

    const { getValues, handleSubmit, register, formState: { errors } } = useForm({
        defaultValues: {
            teamName: user?.store?.platforms?.ios?.teamName,
            teamID: user?.store?.platforms?.ios?.teamID
        }
    });

    function onSubmit() {
        setShowCompanyAccountDialog(true)
    }

    function goToNextStep() {
        let data = getValues();

        // Save the teamName and the teamID
        mobilizeiStoresService
            .updateStoreById(user?.store?.platformId, user?.store?.id, {
                platforms: {
                    ios: {
                        teamName: data.teamName,
                        teamID: data.teamID
                    }
                }
            }).catch(() => null)

        // Set the local user properties
        setUser({
            ...user,
            store: {
                ...user?.store,
                platforms: {
                    ...user?.store?.platforms,
                    ios: {
                        ...user?.store?.platforms?.ios,
                        teamName: data.teamName,
                        teamID: data.teamID
                    }
                }
            }
        })

        nextStep()
    }

    return (
        <Box sx={{ paddingX: 2, paddingY: 4 }}>
            <Typography align="justify" variant="subtitle1" sx={{ mb: 3 }}>
                Para que você possa publicar seu aplicativo na App Store, é necessário que você crie uma conta de desenvolver Apple do tipo <strong>"Empresarial" (Company / Organization)</strong> e registre-se no programa de desenvolvedor iOS. Este programa tem um <strong>custo anual de U$99 pagos diretamente para a Apple.</strong>
            </Typography>

            <Typography align="justify" variant="subtitle1" sx={{ mb: 3 }}>
                Caso você ainda não tenha uma conta de desenvolvedor Apple do tipo empresarial, você pode seguir <a href="https://mobilizei.com.br/tutoriais/como-criar-conta-desenvolvedor-apple" target="_blank">este tutorial</a> onde te mostramos todos os passos necessário para criação da conta.
            </Typography>

            <Typography align="justify" variant="subtitle1" sx={{ mb: 3 }}>
                Depois de receber a confirmação de pagamento da Apple você terá que nos conceder um acesso à sua conta. <a href="https://mobilizei.com.br/tutoriais/convidando-a-mobilizei-para-sua-conta-apple" target="_blank">Clique aqui</a> para verificar como você pode nos conceder este acesso.
            </Typography>

            <Typography align="justify" variant="subtitle1" sx={{ mb: 3 }}>
                E por último, precisamos saber qual é o  <strong>"Nome da entidade"</strong> e o <strong>"ID de equipe"</strong> da sua conta de desenvolvedor. <a href="https://mobilizei.com.br/tutoriais/onde-encontrar-team-name-id-conta-apple" target="_blank">Clique aqui</a> para verificar onde você pode encontrar estas informações e as insira nos campos abaixo.
            </Typography>

            <Grid
                container
                component="form"
                spacing={2}
                direction={'row'}
                justifyContent={'left'}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid item xs={12}>
                    <MbTextField
                        error={errors.teamName?.message}
                        maxLength="300"
                        label="Nome da entidade *"
                        register={register("teamName", {
                            required: "O nome da entidade é obrigatório.",
                        })}
                    />
                </Grid>

                <Grid item xs={12}>
                    <MbTextField
                        error={errors.teamID?.message}
                        maxLength="300"
                        label="ID de equipe *"
                        register={register("teamID", {
                            required: "O ID de equipe é obrigatório."
                        })}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Box display='flex' alignItems='center' marginBottom={1} sx={{ cursor: 'pointer' }} onClick={() => setIsAccountAgreementChecked(!isAccountAgreementChecked)}>
                        <Checkbox checked={isAccountAgreementChecked} sx={{ paddingLeft: 0 }} />
                        <Typography align="justify" variant="subtitle1">
                            Fiz o pagamento da anuidade de U$99 do programa de desenvolvedor Apple, recebi a confirmação do pagamento e tenho uma inscrição ativa no programa de desenvolvedor iOS.
                        </Typography>
                    </Box>

                    <Box display='flex' alignItems='center' marginBottom={1} sx={{ cursor: 'pointer' }} onClick={() => setIsPartnerAgreementChecked(!isPartnerAgreementChecked)}>
                        <Checkbox checked={isPartnerAgreementChecked} sx={{ paddingLeft: 0 }} />
                        <Typography align="justify" variant="subtitle1">
                            Concedi um acesso ao email suporte@mobilizei.com.br à minha conta de desenvolvedor Apple.
                        </Typography>
                    </Box>

                    <Box display='flex' alignItems='center' marginBottom={1} sx={{ cursor: 'pointer' }} onClick={() => setIsContentAgreenmentChecked(!isContentAgreenmentChecked)}>
                        <Checkbox checked={isContentAgreenmentChecked} sx={{ paddingLeft: 0 }} />
                        <Typography align="justify" variant="subtitle1">
                            Garanto que os produtos e o conteúdo da minha loja estão de acordo com as <a target="_blank" href="https://developer.apple.com/app-store/review/guidelines/">Diretrizes da App Store</a>.
                        </Typography>
                    </Box>

                    <Box display='flex' alignItems='center' marginBottom={1} sx={{ cursor: 'pointer' }} onClick={() => setIsTermAgreementChecked(!isTermAgreementChecked)}>
                        <Checkbox checked={isTermAgreementChecked} sx={{ paddingLeft: 0 }} />
                        <Typography align="justify" variant="subtitle1">
                            Li e concordo com os <a href="https://mobilizei.com.br/termos-de-uso" target="_blank">Termos de Uso</a> da Mobilizei.
                        </Typography>
                    </Box>
                </Grid>

                <Box display='flex' justifyContent='center' marginTop={4} width='100%'>
                    <Button disabled={!isAccountAgreementChecked || !isPartnerAgreementChecked || !isContentAgreenmentChecked || !isTermAgreementChecked} variant="contained" type="submit">
                        próximo
                    </Button>
                </Box>
            </Grid>

            <CompanyAccountDialog
                open={showCompanyAccountDialog}
                handleClose={() => setShowCompanyAccountDialog(false)}
                goToNextStep={goToNextStep}
            />
        </Box>
    )
}